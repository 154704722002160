import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import Confirmation from './Confirmation';
import Message from './Message';
import PartnerPage from './PartnerPage';
import OTPModal from './OTPModal';
import FullPageLoader from '../components/FullPageLoader';
import ErrorModal from './ErrorModal';
// import PWC from 'library-javascript-pwc';
import PWC from '../token/PWC';
import Success from './Success';
import SuccessModal from './SuccessModal';
import axios from 'axios';
import SuccessModalSubmit from './SuccessModalSubmit';
import './mainNew.css';
import PartnerPageNew from './PartnerPageNew';
import { useTranslation } from 'react-i18next';
import ShowModel from '../token/ShowModel';
import BrandBadge from '../Widget/BrandBadge/brandBadge.widget';
import MultiBanking from '../components/Banking/banking.component';

const PartnerNew = (props) => {
    const { t, i18n } = useTranslation();
    const { isTranslated, translate, responseT, setHomeWrongToken, token, idT, client_id, backToHome, categoryResponse, logoResponse, appsSettingsResponse, isUserOnboardingDemo, isJulo } = props;

    const partners = responseT;

    const initialECommerceList = [
        {
            code: 'shopee',
            name: 'Shopee',
            iconM: 'images/shopee.png',
            icon: 'images/shopee_3.png',
            category: 'E-Commerce',
            isShowing: partners.find(partner => partner === 'shopee') !== undefined,
            // isShowing: true,
            isSelected: false,
            isBeta: false,
            params: [
                // { key: 'shop_name', value: '', label: 'Shopee shop name:', placeHolder: 'Enter your Shopee shop name', infoMessage: 'Please enter your registered shop name'}
            ],
            modalInfo: {
                title: 'Shopee Authorization',
                linkURL: '/shopeeReference',
                link: 'How to connect your Shopee store?',
                linkT: 'Cara menghubungkan toko Shopee Anda?'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username, Password and Shop Name',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna, Kata Sandi, dan Nama Toko Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Shopee-mu, untuk dapat membaca: ',
            authMessage: 'Silakan login ke akun penjualanmu, lalu klik tombol Selanjutnya',
            authTitle: 'Verifikasi data',
            steps: {
                first: { text: 'Connect Shopee Account', textT: 'Hubungkan Akun Shopee', isActive: true, isCompleted: false },
                second: { text: 'Verify Bank Account', textT: 'Verifikasi Rekening Bank', isActive: false, isCompleted: false },
            },
            isNextStep: true,
            nextStepCode: 'shopeeVerification',
            isNextStepInitiated: false,
        },
        {
            code: 'tokopedia',
            name: 'Tokopedia',
            iconM: 'images/tokopedia.png',
            icon: 'images/tokopedia_3.png',
            category: 'E-Commerce',
            isShowing: partners.find(partner => partner === 'tokopedia') !== undefined,
            // isShowing: true,
            isSelected: false,
            isBeta: false,
            authCounterVar: 0,
            isOnlyProcessAuth: true,
            authCounter(){
                this.authCounterVar++;
                if(this.authCounterVar == 1){
                    return {showMessage: 'Please check your email to approve the link and proceed to the next step.'};
                }
                else if(this.authCounterVar == 2){
                    return {showMessage: 'You have not authorized the verification email, email has been sent again. Please approve the link and proceed to the next step'};
                }
                else if(this.authCounterVar == 3){
                    return {error: 'Email Verification failed'};
                }
            },
            params: [
                { key: 'shop_url', value: 'https://www.tokopedia.com/', label: 'Shop URL', placeHolder: 'Enter Shop URL', infoMessage: 'Please enter your Shop URL', labelT: 'Toko URL', placeHolderT: 'Masukkan URL Toko', infoMessageT: 'Silakan masukkan URL Toko Anda', isPrefixT: 'https://www.tokopedia.com/', errorMessage: 'Please enter Shop URL', errorMessageT: 'Masukkan URL Toko'}
            ],
            paramsHandle(params){
                const url = params['shop_url'].replace('https://www.tokopedia.com/', '');
                if(url.search('tokopedia.com') != -1 || url.search('tokopedia.link') != -1){
                    params['shop_url'] = url;
                }
            },
            modalInfo: {
                title: 'Tokopedia Authorization',
                linkURL: '/tokopediaReference',
                link: 'How to connect your Tokopedia store?',
                linkT: 'Cara menghubungkan toko Tokopedia Anda?'
            },
            infoMessage: 'To connect, please enter your Shop URL, for e.g "https://www.tokopedia.com/<shop_name>"',
            infoMessageT: 'Untuk terhubung, masukkan URL Toko Anda, misalnya "https://www.tokopedia.com/<shop_name>"',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Tokopedia-mu, untuk dapat membaca:',
            steps: {
                first: { text: 'Connect Tokopedia Account', textT: 'Hubungkan Akun Tokopedia', isActive: true, isCompleted: false },
                second: { text: 'Verify Bank Account', textT: 'Verifikasi Rekening Bank', isActive: false, isCompleted: false },
            },
            isNextStep: true,
            nextStepCode: 'tokopediaVerification',
            isNextStepInitiated: false,
        },
        {
            code: 'lazada',
            name: 'Lazada',
            iconM: 'images/lazada_icon.png',
            icon: 'images/lazada_icon_3.png',
            category: 'E-Commerce',
            isShowing: partners.find(partner => partner === 'lazada') !== undefined,
            // isShowing: true,
            isSelected: false,
            isBeta: false,
            params: [
                { key: 'region', value: '', label: 'Please select your region', type: 'options', options: ['Indonesia', 'Singapore', 'Malaysia', 'Thailand', 'Vietnam', 'Philippines'], infoMessage: 'Please select your region where your Shop belongs, ex. Indonesia', labelT: 'Pilih wilayah', infoMessageT: 'Silakan pilih wilayah tempat Toko Anda berada, mis. Indonesia'}
            ],
            modalInfo: {
                title: 'Authorize Lazada Seller'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username and Password',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Lazada-mu, untuk dapat membaca:',
            authMessage: 'Silakan login ke akun penjualanmu, lalu klik tombol Selanjutnya',
            authTitle: 'Verifikasi data',
        },
        {
            code: 'blibli',
            name: 'Blibli',
            iconM: 'images/blibli.png',
            icon: 'images/blibli_5.png',
            iconWidth: '90px',
            category: 'E-Commerce',
            isShowing: partners.find(partner => partner === 'blibli') !== undefined,
            // isShowing: true,
            isSelected: false,
            isBeta: true,
            params: [
                { key: 'username', value: '', label: 'Email id', placeHolder: 'Enter your Blibli seller registered email id', infoMessage: 'Please enter your registered email address', labelT: 'Email penjual', placeHolderT: 'Masukkan id email terdaftar penjual Blibli Anda', infoMessageT: 'Silakan masukkan alamat email Anda yang terdaftar'},
                { key: 'store_code', value: '', label: 'Store id', placeHolder: 'Enter your Store id', infoMessage: 'Please enter your Store id', labelT: 'ID toko', placeHolderT: 'Masukkan ID Toko Anda', infoMessageT: 'Silakan masukkan id Toko Anda'},
                { key: 'api_seller_key', value: '', label: 'API seller key', placeHolder: 'Enter your API seller key', infoMessage: 'Please enter your API seller key', labelT: 'Kunci penjual API', placeHolderT: 'Masukkan kunci penjual API Anda', infoMessageT: 'Silakan masukkan kunci penjual API Anda'}
            ],
            modalInfo: {
                title: 'Blibli Authorization',
                specialNote: '*Note: Seller have to bind our API Client ID before entering below details.',
                specialNoteT: '*Catatan: Penjual harus menautkan ID Klien API kami sebelum memasukkan detail di bawah ini',
                linkURL: '/BlibliStoreBindReference',
                link: 'How to bind and connect your Blibli store?',
                linkT: 'Bagaimana cara menautkan & menghubungkan toko Blibli penjualanmu?'
            },
            infoMessage: 'To connect, please enter API seller key, Store id and registered email id',
            infoMessageT: 'Untuk terhubung, masukkan kunci penjual API, id Toko, dan id email terdaftar',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Blibli-mu, untuk dapat membaca:',
            authMessage: 'Silakan login ke akun penjualanmu, lalu klik tombol Selanjutnya',
            authTitle: 'Verifikasi data',
        },
        {
            code: 'xero',
            name: 'Xero',
            iconM: 'images/xero.png',
            icon: 'images/xero_4.png',
            iconWidth: '90px',
            category: 'Accounting & POS',
            isShowing: partners.find(partner => partner === 'xero') !== undefined,
            // isShowing: true,
            isSelected: false,
            isBeta: true,
            params: [
                { key: 'business_name', value: '', label: 'Organization name:', placeHolder: 'Enter organization name', infoMessage: 'Please enter your organization name', labelT: 'Nama bisnis', placeHolderT: 'Masukkan nama bisnis terdaftar', infoMessageT: 'Silakan masukkan nama organisasi Anda'}
            ],
            modalInfo: {
                title: 'Xero Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username, Password and Business Name',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna, Kata Sandi, dan Nama Bisnis Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Xero-mu, untuk dapat membaca:',
            authMessage: 'Silakan login ke akun-mu, lalu klik tombol Selanjutnya',
            authTitle: 'Verifikasi data',
            errorMessage: {
                head: 'Error',
                body: 'Unable to process data, please try again!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Koneksi gagal. Coba lagi, ya!'
            },
        },
        {
            code: 'jurnal',
            name: 'Jurnal',
            iconM: 'images/jurnal_icon.png',
            icon: 'images/jurnal_icon_3.png',
            // iconWidth: '130px',
            category: 'Accounting & POS',
            isShowing: partners.find(partner => partner === 'jurnal') !== undefined,
            // isShowing: true,
            isSelected: false,
            isBeta: true,
            params: [
                { key: 'user_email', value: '', type: "email", label: 'Email id', placeHolder: 'Enter email id', infoMessage: 'Please enter your Email address', labelT: 'Email Jurnal', placeHolderT: 'Masukkan ID email terdaftar', infoMessageT: 'Silakan masukkan alamat Email Anda'}
            ],
            modalInfo: {
                title: 'Jurnal Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username and Password',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Jurnal-mu, untuk dapat membaca:',
            authMessage: 'Silakan login ke akun-mu, lalu klik tombol Selanjutnya',
            authTitle: 'Verifikasi data',
            errorMessage: {
                head: 'Error',
                body: 'Unable to process data, please try again!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Koneksi gagal. Coba lagi, ya!'
            },
        },
        {
            code: 'accurate',
            name: 'Accurate',
            iconM: 'images/accurate_icon.png',
            icon: 'images/accurate_icon_1.png',
            // iconWidth: '130px',
            category: 'Accounting & POS',
            isShowing: partners.find(partner => partner === 'accurate') !== undefined,
            // isShowing: false,
            isSelected: false,
            isBeta: true,
            params: [],
            modalInfo: {
                title: 'Accurate Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username and Password',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Accurate-mu, untuk dapat membaca:',
            authMessage: 'Silakan login ke akun-mu, lalu klik tombol Selanjutnya',
            authTitle: 'Verifikasi data',
            errorMessage: {
                head: 'Error',
                body: 'Unable to process data, please try again!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Koneksi gagal. Coba lagi, ya!'
            },
        },
        {
            code: 'quickbooks',
            name: 'Quickbooks',
            iconM: 'images/quickbooks_icon.png',
            icon: 'images/quickbooks_icon_1.png',
            // iconWidth: '130px',
            category: 'Accounting & POS',
            isShowing: partners.find(partner => partner === 'quickbooks') !== undefined,
            // isShowing: false,
            isSelected: false,
            isBeta: true,
            params: [],
            modalInfo: {
                title: 'Quickbooks Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Username and Password',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Quickbooks-mu, untuk dapat membaca:',
            authMessage: 'Silakan login ke akun-mu, lalu klik tombol Selanjutnya',
            authTitle: 'Verifikasi data',
            errorMessage: {
                head: 'Error',
                body: 'Unable to process data, please try again!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Koneksi gagal. Coba lagi, ya!'
            },
        },
        {
            code: 'mokapos',
            name: 'Mokapos',
            iconM: 'images/mokapos_icon.webp',
            icon: 'images/mokapos_icon_2.png',
            iconWidth: '90px',
            category: 'Accounting & POS',
            isShowing: partners.find(partner => partner === 'mokapos') !== undefined,
            // isShowing: true,
            isSelected: false,
            isBeta: true,
            params: [
                { key: 'business_name', value: '', label: 'Business name', placeHolder: 'Enter your business name', infoMessage: 'Please enter your business name', labelT: 'Nama bisnis', placeHolderT: 'Masukkan nama bisnis terdaftar', infoMessageT: 'Silakan masukkan nama bisnis Anda'}
            ],
            modalInfo: {
                title: 'Mokapos Authorization'
            },
            isOAuth: true,
            infoMessage: 'To connect, please enter your Business name',
            infoMessageT: 'Untuk terhubung, masukkan nama Bisnis Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Mokapos-mu, untuk dapat membaca:',
            authMessage: 'Silakan login ke akun-mu, lalu klik tombol Selanjutnya',
            authTitle: 'Verifikasi data',
            errorMessage: {
                head: 'Error',
                body: 'Unable to process data, please try again!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Koneksi gagal. Coba lagi, ya!'
            },
        },
        {
            code: 'ovo',
            name: 'Ovo',
            iconM: 'images/ovo_icon.jpeg',
            icon: 'images/ovo_icon_3.png',
            iconWidth: '90px',
            category: 'Payment',
            // isShowing: partners.find(partner => partner === 'ovo') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: true,
            params: [
                { key: 'username', value: '', type: 'number', label: 'Phone number', placeHolder: 'Enter your phone number', isPhoneNo: true, infoMessage: 'Please enter your phone number', labelT: 'Nomor telepon', placeHolderT: 'Masukkan nomor telepon terdaftar', infoMessageT: 'Masukkan nomor telepon anda'},
                { key: 'password', value: '', type: 'password', label: 'PIN', placeHolder: 'Enter your PIN', infoMessage: 'Please enter your PIN', labelT: 'PIN OVO', placeHolderT: 'Masukkan PIN OVO', infoMessageT: 'Silakan masukkan PIN Anda'},
            ],
            paramsOTP: [
                { key: 'otp', value: '', type: 'number', label: 'OTP', placeHolder: 'Enter your OTP', infoMessage: 'Please enter your OTP', labelT: 'OTP', placeHolderT: 'Masukkan OTP Anda', infoMessageT: 'Silakan masukkan OTP Anda'},
            ],
            modalInfo: {
                title: 'Ovo Authorization',
                titleT: 'Otorisasi Ovo'
            },
            isOTP: true,
            errorMessage: {
                head: 'Error',
                body: 'Unable to process data, please try again!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Koneksi gagal. Coba lagi, ya!'
            },
            paramsHandle(params){
                params['username'] = `+62${params['username']}`;
            },
            infoMessage: 'To connect, please enter your username and OTP PIN',
            infoMessageT: 'Untuk terhubung, masukkan nama pengguna dan PIN OTP Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun OVO-mu, untuk dapat membaca:',
        },
        {
            code: 'gopay',
            name: 'Gopay',
            iconM: 'images/gopay_icon.jpeg',
            icon: 'images/gopay_icon_3.png',
            category: 'Payment',
            isShowing: partners.find(partner => partner === 'gopay') !== undefined,
            // isShowing: true,
            isSelected: false,
            isBeta: true,
            params: [
                { key: 'contact', value: '', type: 'number', label: 'Phone number', placeHolder: 'Enter your phone number', isPhoneNo: true, infoMessage: 'Please enter your phone number', labelT: 'Nomor telepon', placeHolderT: 'Masukkan nomor telepon Anda', infoMessageT: 'Masukkan nomor telepon anda'},
                // { key: 'password', value: '', type: 'password', label: 'PIN', placeHolder: 'Enter your PIN', infoMessage: 'Please enter your PIN', labelT: 'PIN GoPay', placeHolderT: 'Masukkan PIN GoPay', infoMessageT: 'Silakan masukkan PIN Anda'},
            ],
            paramsOTP: [
                { key: 'otp', value: '', type: 'number', maxLength: 4, label: 'OTP', placeHolder: 'Enter your OTP', infoMessage: 'Please enter your OTP', labelT: 'OTP', placeHolderT: 'Masukkan OTP GoPay', infoMessageT: 'Silakan masukkan OTP Anda'},
            ],
            modalInfo: {
                title: 'Gopay Authorization',
                titleT: 'Otorisasi Gopay'
            },
            isOTP: true,
            showOTP: false,
            isResend: false,
            isOnlyProcessAuth: true,
            errorMessage: {
                head: 'Error',
                body: 'Unable to process data, please try again!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Koneksi gagal. Coba lagi, ya!'
            },
            paramsHandle(params){
                params['contact'] = `+62${params['contact']}`;
            },
            getButtonText(isTranslated){
                if(this.showOTP){
                    return isTranslated ? 'Verifikasi OTP' : 'Verify OTP';
                }
                else{
                    return isTranslated ? 'Kirim OTP' : 'Send OTP';
                }
            },
            infoMessage: 'To connect, please enter your username and OTP PIN',
            infoMessageT: 'Untuk terhubung, masukkan nama pengguna dan PIN OTP Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun GoPay-mu, untuk dapat membaca:',
        },
        {
            code: 'bca_bc',
            name: 'BCA',
            // icon: 'images/BCA.png',
            icon: 'images/BCA_2.png',
            iconWidth: '130px',
            category: 'Banking',
            isBanks: true,
            selectedBank: null,
            // isShowing: partners.find(partner => partner === 'bankconnect' || partner === 'altconnect') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'bankType', value: 'Retail/Personal Banking', type: 'radio', values: ['Retail/Personal Banking', 'Corporate Banking'], label: 'Select your acount type', placeHolder: 'Select your acount type', infoMessage: 'Please select your acount type', labelT: 'Pilih jenis akun Anda', placeHolderT: 'Pilih jenis akun Anda', infoMessageT: 'Silakan pilih jenis akun Anda'},
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username', labelT: 'Nama belakang', placeHolderT: 'Masukkan nama pengguna', infoMessageT: 'Silakan masukkan Nama Pengguna Anda'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password', labelT: 'Kata sandi', placeHolderT: 'Masukkan kata kunci', infoMessageT: 'Silakan masukkan Kata Sandi Anda'}
            ],
            modalInfo: {
                title: 'Banking Authorization'
            },
            infoMessage: 'To connect, please enter your Username and Password',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',

        },
        {
            code: 'mandiri_bc',
            name: 'Mandiri',
            // icon: 'images/Mandiri_1.png',
            icon: 'images/Mandiri_2.png',
            category: 'Banking',
            // isShowing: partners.find(partner => partner === 'bankconnect' || partner === 'altconnect') !== undefined,
            isShowing: false,
            isSelected: false,
            isBanks: true,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'bankType', value: 'Retail/Personal Banking', type: 'radio', values: ['Retail/Personal Banking', 'Corporate Banking'], label: 'Select your acount type', placeHolder: 'Select your acount type', infoMessage: 'Please select your acount type', labelT: 'Pilih jenis akun Anda', placeHolderT: 'Pilih jenis akun Anda', infoMessageT: 'Silakan pilih jenis akun Anda'},
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username', labelT: 'Nama belakang', placeHolderT: 'Masukkan nama pengguna', infoMessageT: 'Silakan masukkan Nama Pengguna Anda'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password', labelT: 'Kata sandi', placeHolderT: 'Masukkan kata kunci', infoMessageT: 'Silakan masukkan Kata Sandi Anda'}
            ],
            modalInfo: {
                title: 'Banking Authorization'
            },
            infoMessage: 'To connect, please enter your Username and Password',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',

        },
        {
            code: 'bri_bc',
            name: 'BRI',
            icon: 'images/BRI_2.png',
            category: 'Banking',
            // isShowing: partners.find(partner => partner === 'bankconnect' || partner === 'altconnect') !== undefined,
            isShowing: false,
            isSelected: false,
            isBanks: true,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'bankType', value: 'Retail/Personal Banking', type: 'radio', values: ['Retail/Personal Banking'], label: 'Select your acount type', placeHolder: 'Select your acount type', infoMessage: 'Please select your acount type', labelT: 'Pilih jenis akun Anda', placeHolderT: 'Pilih jenis akun Anda', infoMessageT: 'Silakan pilih jenis akun Anda'},
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username', labelT: 'Nama belakang', placeHolderT: 'Masukkan nama pengguna', infoMessageT: 'Silakan masukkan Nama Pengguna Anda'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password', labelT: 'Kata sandi', placeHolderT: 'Masukkan kata kunci', infoMessageT: 'Silakan masukkan Kata Sandi Anda'}
            ],
            modalInfo: {
                title: 'Banking Authorization'
            },
            infoMessage: 'To connect, please enter your Username and Password',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',

        },
        {
            code: 'bni_bc',
            name: 'BNI',
            // icon: 'images/BNI_1.png',
            icon: 'images/BNI_logo_2.png',
            iconWidth: '90px',
            category: 'Banking',
            // isShowing: partners.find(partner => partner === 'bankconnect' || partner === 'altconnect') !== undefined,
            isShowing: false,
            isSelected: false,
            isBanks: true,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'bankType', value: 'Retail/Personal Banking', type: 'radio', values: ['Retail/Personal Banking', 'Corporate Banking'], label: 'Select your acount type', placeHolder: 'Select your acount type', infoMessage: 'Please select your acount type', labelT: 'Pilih jenis akun Anda', placeHolderT: 'Pilih jenis akun Anda', infoMessageT: 'Silakan pilih jenis akun Anda'},
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username', labelT: 'Nama belakang', placeHolderT: 'Masukkan nama pengguna', infoMessageT: 'Silakan masukkan Nama Pengguna Anda'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password', labelT: 'Kata sandi', placeHolderT: 'Masukkan kata kunci', infoMessageT: 'Silakan masukkan Kata Sandi Anda'}
            ],
            modalInfo: {
                title: 'Banking Authorization'
            },
            infoMessage: 'To connect, please enter your Username and Password',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
        },
        {
            code: 'location',
            name: 'Google Location',
            iconM: 'images/gl_icon.jpg',
            icon: 'images/location_4.png',
            category: 'Social',
            isShowing: partners.find(partner => partner === 'location') !== undefined,
            // isShowing: true,
            isSelected: false,
            isBeta: false,
            params: [
                { key: 'place_search_text', value: '', label: 'Place name', placeHolder: 'Ex: Google Singapore, Sarasbaug Pune', infoMessage: 'Please enter your location', labelT: 'Lokasi', placeHolderT: 'Contoh: Toko ABC', infoMessageT: 'Silakan masukkan lokasi Anda'}
            ],
            modalInfo: {
                title: 'Google Location Fetching'
            },
            infoMessage: 'To connect, please enter the location name. For e,g Google Singapore',
            infoMessageT: 'Untuk terhubung, masukkan nama lokasi. Misalnya, Google Singapura',
            partnerPageMainMessageT: 'Kamu akan diminta memberi info Google Location toko atau tempat-mu, untuk dapat membaca:',
            partnerPageHeadingT: 'Google Location Anda',
        },
        {
            code: 'instagram',
            name: 'Instagram',
            iconM: 'images/instagram_icon.png',
            icon: 'images/instagram_icon_3.png',
            category: 'Social',
            isShowing: partners.find(partner => partner === 'instagram') !== undefined,
            // isShowing: true,
            isSelected: false,
            isOAuth: true,
            isBeta: true,
            params: [
                // { key: 'profile_id', value: '', label: 'Enter your profile id:', placeHolder: 'Enter profile id', infoMessage: 'Please enter your profile', labelT: 'Masukkan ID profil Anda:', placeHolderT: 'Masukkan id profil', infoMessageT: 'Silakan masukkan profil Anda'}
            ],
            modalInfo: {
                title: 'Instagram Authorization'
            },
            infoMessage: 'To connect, please enter your profile handle. This should be a public profile. For e.g Nike',
            infoMessageT: 'Untuk menghubungkan, masukkan pegangan profil Anda. Ini harus menjadi profil publik. Untuk misalnya Nike',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun Instagram-mu, untuk dapat membaca:',
            partnerPageHeadingT: 'Akun Instagram Anda',
        },
        {
            code: 'tiktok',
            name: 'tiktok',
            iconM: 'images/tiktok.png',
            icon: 'images/tiktok.png',
            category: 'E-Commerce',
            isShowing: partners.find(partner => partner === 'tiktok') !== undefined,
            //isShowing: false,
            isSelected: false,
            isOAuth: true,
            //isBeta: true,
            params: [
                // { key: 'profile_id', value: '', label: 'Enter your profile id:', placeHolder: 'Enter profile id', infoMessage: 'Please enter your profile', labelT: 'Masukkan ID profil Anda:', placeHolderT: 'Masukkan id profil', infoMessageT: 'Silakan masukkan profil Anda'}
            ],
            modalInfo: {
                title: 'Tiktok Authorization'
            },
            infoMessage: 'To connect, please enter your profile handle. This should be a public profile. For e.g Nike',
            infoMessageT: 'Untuk menghubungkan, masukkan pegangan profil Anda. Ini harus menjadi profil publik. Untuk misalnya Nike',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses akun tiktok-mu, untuk dapat membaca:',
            partnerPageHeadingT: 'Akun Tiktok Anda',
        },
        {
            code: 'bankconnect',
            name: 'Retail/Personal Banking',
            icon: '',
            category: 'Retail/Personal Banking',
            // isShowing: partners.find(partner => partner === 'bankconnect') !== undefined,
            isShowing: false,
            // isHidden: true,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username', labelT: 'Username', placeHolderT: 'Masukkan Username', infoMessageT: 'Silakan masukkan Nama Pengguna Anda'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password', labelT: 'Password', placeHolderT: 'Masukkan Password', infoMessageT: 'Silakan masukkan Kata Sandi Anda'}
            ],
            modalInfo: {
                title: 'Retail/Personal Banking Authorization'
            },
            banks: [
                {
                    code: 'bca_bc',
                    name: 'BCA',
                    iconM: 'images/BCA.png',
                    icon: 'images/BCA_2.png',
                    iconWidth: '90px',
                    isSelected: false,
                    data: { bank_code: 'BCA_PERSONAL' },
                    infoMessage: 'To connect, please enter your Username and Password',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
                    partnerPageMainMessageT: 'Kamu akan diminta memberikan akses terhadap Akun KlikBCA-Personal anda, untuk dapat membaca:',
                    partnerPageHeadingT: 'Hubungkan ke akun KlikBCA-Personal anda',
                },
                // {
                //     code: 'mandiri_bc',
                //     name: 'Mandiri',
                //     icon: 'images/Mandiri_2.png',
                //     iconM: 'images/Mandiri.webp',
                //     isSelected: false,
                //     data: { bank_code: 'MANDIRI_PERSONAL' },
                //     infoMessage: 'To connect, please enter your Username and Password',
                //     infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
                //     partnerPageMainMessageT: "Kamu akan diminta memberikan akses terhadap Akun Mandiri Livin’ anda, untuk dapat membaca:",
                //     partnerPageHeadingT: "Hubungkan ke akun Mandiri Livin’ anda",
                // },
                {
                    code: 'bri_bc',
                    name: 'BRI',
                    icon: 'images/BRI_2.png',
                    iconM: 'images/BRI.webp',
                    isSelected: false,
                    data: { bank_code: 'BRI_PERSONAL' },
                    infoMessage: 'To connect, please enter your Username and Password',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
                    partnerPageMainMessageT: 'Kamu akan diminta memberikan akses terhadap akun Internet Banking BRI anda, untuk dapat membaca:',
                    partnerPageHeadingT: 'Hubungkan ke akun Internet Banking BRI anda',
                },
                {
                    code: 'bni_bc',
                    name: 'BNI',
                    icon: 'images/BNI_1.png',
                    iconWidth: '70px',
                    iconM: 'images/BNI.webp',
                    isSelected: false,
                    data: { bank_code: 'BNI_PERSONAL' },
                    infoMessage: 'To connect, please enter your Username and Password',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
                    partnerPageMainMessageT: 'Kamu akan diminta memberikan akses terhadap akun Internet Banking BNI anda, untuk dapat membaca:',
                    partnerPageHeadingT: 'Hubungkan ke akun Internet Banking BNI anda',
                }
            ],
            data: {
                bank_code: null
            },
            errorMessage: {
                head: 'Failed Process',
                body: 'Please check your Username/Password. Try again, OK!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Harap periksa Username/Password anda. Coba lagi, ya!'
            },
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
        },
        {
            code: 'bankVerification',
            name: 'Bank Verification',
            iconM: 'bank-verification.jpeg',
            icon: 'images/bank-verification_3.png',
            iconWidth: '50px',
            category: 'Bank Verification',
            isVerification: true,
            // isHidden: true,
            // isShowing: partners.find(partner => partner === 'bankVerification') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'bank', isOnlyDesktopView: true, value: 'BCA', label: 'Please select your bank', type: 'options', options: ['BCA', 'Mandiri', 'BRI', 'BNI', 'CIMB', 'Danamon'], infoMessage: 'Please select your bank account', labelT: 'Silakan pilih bank Anda', infoMessageT: 'Silakan pilih rekening bank Anda'},

                { key: 'name', value: '', label: 'Name', placeHolder: 'Enter Name', infoMessage: 'Please enter your Name', labelT: 'Nama Lengkap', placeHolderT: 'Masukkan nama lengkap anda', infoMessageT: 'Silahkan masukan nama anda'},
                { key: 'nik', value: '', label: 'NIK', placeHolder: 'Enter NIK', infoMessage: 'Please enter your NIK', labelT: 'No KTP/NIK', placeHolderT: 'Masukkan no KTP / NIK anda', infoMessageT: 'Silakan masukkan NIK Anda'},
                { key: 'account_number', value: '', label: 'Account Number', placeHolder: 'Enter Account Number', infoMessage: 'Please enter your Account Number', labelT: 'Nomor Rekening', placeHolderT: 'Masukkan nomor rekening anda', infoMessageT: 'Silakan masukkan Nomor Rekening Anda'}
            ],
            modalInfo: {
                title: 'Bank Verification'
            },
            banks: [
                {
                    code: 'bca_bcv',
                    name: 'BCA',
                    icon: 'images/BCA_2.png',
                    iconWidth: '90px',
                    iconM: 'images/BCA.png',
                    isSelected: false,
                    data: { bank_short_code: 'BCA' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama, NIK, dan Nomor Rekening Anda',
                    partnerPageMainMessageT: "Kamu akan diminta memberikan akses terhadap akun Internet Banking BCA / BCA Corporate iBanking anda, untuk dapat membaca:",
                    partnerPageHeadingT: "Hubungkan ke akun Internet Banking BCA / BCA Corporate iBanking anda",
                },
                {
                    code: 'mandiri_bcv',
                    name: 'Mandiri',
                    icon: 'images/Mandiri_2.png',
                    iconM: 'images/Mandiri.webp',
                    isSelected: false,
                    data: { bank_short_code: 'MANDIRI' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama, NIK, dan Nomor Rekening Anda',
                    partnerPageMainMessageT: "Kamu akan diminta memberikan akses terhadap akun Mandiri Livin’ atau Mandiri Internet Bisnis anda, untuk dapat membaca:",
                    partnerPageHeadingT: "Hubungkan ke akun Mandiri Livin’ atau Mandiri Internet Bisnis anda",
                },
                {
                    code: 'bri_bcv',
                    name: 'BRI',
                    icon: 'images/BRI_2.png',
                    iconM: 'images/BRI.webp',
                    isSelected: false,
                    data: { bank_short_code: 'BRI' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama, NIK, dan Nomor Rekening Anda',
                    partnerPageMainMessageT: "Kamu akan diminta memberikan akses terhadap akun Internet Banking BRI anda, untuk dapat membaca:",
                    partnerPageHeadingT: "Hubungkan ke akun Internet Banking BRI anda",
                },
                {
                    code: 'bni_bcv',
                    name: 'BNI',
                    icon: 'images/BNI_1.png',
                    iconWidth: '70px',
                    iconM: 'images/BNI.webp',
                    isSelected: false,
                    data: { bank_short_code: 'BNI' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama, NIK, dan Nomor Rekening Anda',
                    partnerPageMainMessageT: "Kamu akan diminta memberikan akses terhadap akun Internet Banking BNI / BNI Corporate iBanking anda, untuk dapat membaca:",
                    partnerPageHeadingT: "Hubungkan ke akun Internet Banking BNI / BNI Corporate iBanking anda",
                },
                {
                    code: 'cimb_bcv',
                    name: 'CIMB',
                    icon: 'images/CIMB.png',
                    iconM: 'images/CIMB.png',
                    isSelected: false,
                    data: { bank_short_code: 'CIMB_NIAGA' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama, NIK, dan Nomor Rekening Anda',
                    partnerPageMainMessageT: "Kamu akan diminta memberikan akses terhadap akun Internet Banking CIMB anda, untuk dapat membaca:",
                    partnerPageHeadingT: "Hubungkan ke akun Internet Banking CIMB anda",
                },
                {
                    code: 'danamon_bcv',
                    name: 'Danamon',
                    icon: 'images/danamon.png',
                    iconM: 'images/danamon.png',
                    isSelected: false,
                    data: { bank_short_code: 'DANAMON' },
                    infoMessage: 'To connect, please enter your Name, NIK and Account Number',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama, NIK, dan Nomor Rekening Anda',
                    partnerPageMainMessageT: "Kamu akan diminta memberikan akses terhadap akun Internet Banking Danamon anda, untuk dapat membaca:",
                    partnerPageHeadingT: "Hubungkan ke akun Internet Banking Danamon anda",
                }
            ],
            data: {
                bank_short_code: null
            },
            errorMessage: {
                head: 'Failed Process',
                body: 'Please check your Name/KTP/Account Number. Try again, OK!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Harap periksa Nama/No KTP/Nomor Rekening anda. Coba lagi, ya!'
            },
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
            infoMessage: 'To connect, please enter your Bank, Name, NIK and Account Number',
            infoMessageT: 'Untuk terhubung, silahkan masukkan Bank, Nama, NIK dan Nomor Rekening Anda'
        },
        {
            code: 'altconnect',
            name: 'Corporate Banking',
            icon: '',
            category: 'Corporate Banking',
            // isShowing: partners.find(partner => partner === 'altconnect') !== undefined,
            isShowing: false,
            // isHidden: true,
            isSelected: false,
            isBeta: true,
            isOnlyProcessAuth: true,
            params: [
                { key: 'username', value: '', label: 'Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username', labelT: 'Username', placeHolderT: 'Masukkan nama pengguna', infoMessageT: 'Masukkan Username'},
                { key: 'password', value: '', type: 'password', label: 'Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password', labelT: 'Password', placeHolderT: 'Masukkan kata kunci', infoMessageT: 'Masukkan Password'}
            ],
            modalInfo: {
                title: 'Corporate Banking Authorization'
            },
            banks: [
                {
                    code: 'bca_ac',
                    name: 'BCA',
                    iconM: 'images/BCA.png',
                    icon: 'images/BCA_2.png',
                    iconWidth: '90px',
                    isSelected: false,
                    data: { bank_code: 'BCA_CORPORATE' },
                    infoMessage: 'To connect, please enter your Username and Password',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
                    partnerPageMainMessageT: 'Kamu akan diminta memberikan akses terhadap akun KlikBCA-Bisnis anda, untuk dapat membaca:',
                    partnerPageHeadingT: 'Hubungkan ke akun KlikBCA-Bisnis anda',
                },
                // {
                //     code: 'mandiri_ac',
                //     name: 'Mandiri',
                //     iconM: 'images/Mandiri.webp',
                //     icon: 'images/Mandiri_2.png',
                //     isSelected: false,
                //     data: { bank_code: 'MANDIRI_CORPORATE' },
                //     infoMessage: 'To connect, please enter your Username and Password',
                //     infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
                //     partnerPageMainMessageT: 'Kamu akan diminta memberikan akses terhadap akun Mandiri Internet Bisnis anda, untuk dapat membaca:',
                //     partnerPageHeadingT: 'Hubungkan ke akun Mandiri Internet Bisnis anda',
                // },
                {
                    code: 'bni_ac',
                    name: 'BNI',
                    icon: 'images/BNI_1.png',
                    iconWidth: '70px',
                    iconM: 'images/BNI.webp',
                    isSelected: false,
                    data: { bank_code: 'BNI_CORPORATE' },
                    infoMessage: 'To connect, please enter your Username and Password',
                    infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna dan Kata Sandi Anda',
                    partnerPageMainMessageT: 'Kamu akan diminta memberikan akses terhadap akun BNI Corporate iBanking anda, untuk dapat membaca:',
                    partnerPageHeadingT: 'Hubungkan ke akun BNI Corporate iBanking anda',
                }
            ],
            data: {
                bank_code: null
            },
            errorMessage: {
                head: 'Failed Process',
                body: 'Please check your Username/Password. Try again, OK!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Harap periksa Username/Password anda. Coba lagi, ya!'
            },
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
        },
        {
            code: 'shopeeVerification',
            name: 'Shopee',
            iconM: 'images/shopee.png',
            icon: 'images/shopee_3.png',
            category: 'E-Commerce Verification',
            // category: 'Shopee Verification',
            isShowing: partners.find(partner => partner === 'shopeeVerification') !== undefined,
            isShowing: false,
            isSelected: false,
            isVerification: true,
            // isHidden: true,
            isBeta: true,
            params: [
                { key: 'username', value: '', label: 'Shopee Username', placeHolder: 'Enter Username', infoMessage: 'Please enter your Username', labelT: 'Shopee Username', placeHolderT: 'Masukkan username', infoMessageT: 'Silakan masukkan Nama Pengguna Anda'},
                { key: 'password', value: '', type: 'password', label: 'Shopee Password', placeHolder: 'Enter Password', infoMessage: 'Please enter your Password', labelT: 'Shopee Password', placeHolderT: 'Masukkan Password anda', infoMessageT: 'Silakan masukkan Kata Sandi Anda'},
                { key: 'accountNo', value: '', label: 'Bank Account Number', placeHolder: 'Enter Bank Account Number', infoMessage: 'Please enter your Bank Account Number', labelT: 'Nomor Rekening Bank', placeHolderT: 'Masukkan no rekening terdaftar di Shopee', infoMessageT: 'Silakan masukkan Nomor Rekening Bank Anda'},
                { key: 'method', value: 'Whatsapp', label: 'Receive Shopee verification link via', type: 'radio', values: [{name: 'Whatsapp' , value: 'Whatsapp'}, {name: 'SMS' , value: 'SMS'}], infoMessage: 'Please select an option to receive shopee authentication link', labelT: 'Terima tautan verifikasi Shopee melalui', infoMessageT: 'Silakan pilih opsi untuk menerima tautan autentikasi shopee'}
            ],
            modalInfo: {
                title: 'Shopee Verification'
            },
            infoMessage: 'To connect, please enter your Username, Password and your bank account number linked to your shopee account',
            infoMessageT: 'Untuk terhubung, silakan masukkan Nama Pengguna, Kata Sandi, dan nomor rekening bank Anda yang ditautkan ke akun shopee Anda',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses terhadap akun Shopee anda, untuk dapat membaca:',
            partnerPageHeadingT: 'Hubungkan ke akun Shopee anda',
            getData(isTranslated){
                return isTranslated
                    ? ['Nama pengguna Shopee', 'Kata sandi Shopee', 'Nomor rekening bank pembayaran Shopee']
                    : ['Shopee username', 'Shopee password', 'Shopee payout bank account number'];

            },
            isPreviousStep: true,
            previousStepCode: 'shopee',
            showMessageBeforeRequest: 'Please click on the verification link sent to you on your mobile number.',
            showMessageBeforeRequestT: 'Silakan klik tautan verifikasi yang dikirimkan kepada Anda di nomor ponsel Anda.',
        },
        {
            code: 'tokopediaVerification',
            name: 'Tokopedia',
            iconM: 'images/tokopedia.png',
            icon: 'images/tokopedia_3.png',
            category: 'E-Commerce Verification',
            // category: 'Tokopedia Verification',
            isShowing: partners.find(partner => partner === 'tokopediaVerification') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: true,
            isVerification: true,
            // isHidden: true,
            params: [
                // { key: 'shop_url', value: '', label: 'Enter your Shop URL', placeHolder: 'Enter Shop URL', infoMessage: 'Please enter your Shop URL', labelT: 'Masukkan URL Toko Anda', placeHolderT: 'Masukkan URL Toko', infoMessageT: 'Silakan masukkan URL Toko Anda', isPrefixT: true},
                { key: 'bank_acc', value: '', label: 'Bank account number', placeHolder: 'Enter bank account', infoMessage: 'Please enter your bank account', labelT: 'Nomor rekening bank', placeHolderT: 'Masukkan no rekening terdaftar di Tokopedia', infoMessageT: 'Silakan masukkan rekening bank Anda'}
            ],
            // paramsHandle(params){
            //     params['shop_url'] = `https://www.tokopedia.com/${params['shop_url']}`;
            // },
            modalInfo: {
                title: 'Tokopedia Authorization'
            },
            infoMessage: 'To connect, please enter your Shop URL, for e.g "https://www.tokopedia.com/<shop_name>" and Bank account',
            infoMessageT: 'Untuk terhubung, masukkan URL Toko Anda, misalnya "https://www.tokopedia.com/<shop_name>" dan rekening Bank',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses terhadap akun Tokopedia anda, untuk dapat membaca:',
            partnerPageHeadingT: 'Hubungkan ke akun Tokopedia anda',
            isPreviousStep: true,
            previousStepCode: 'tokopedia',
        },
        {
            code: 'plncheck',
            name: 'PLN',
            iconM: 'images/plncheck_icon.jpeg',
            icon: 'images/plncheck_icon_1.png',
            iconWidth: '30px',
            category: 'Utility Bills',
            isShowing: partners.find(partner => partner === 'plncheck') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: true,
            params: [
                { key: 'customername', value: '', label: 'Customer name', placeHolder: 'Enter your customer name', infoMessage: 'Please enter your customer name', labelT: 'Nama pelanggan', placeHolderT: 'Masukkan nama pelanggan terdaftar', infoMessageT: 'Silakan masukkan nama pelanggan Anda'},
                { key: 'phonenumber', value: '', type: 'number', label: 'Phone number', placeHolder: 'Enter your phone number', infoMessage: 'Please enter your phone number', labelT: 'Nomor telepon', placeHolderT: 'Masukkan nomor telepon anda', infoMessageT: 'Masukkan nomor telepon anda'},
                { key: 'accountnumber', value: '', label: 'Account number', placeHolder: 'Enter your account number', infoMessage: 'Please enter your account number', labelT: 'Nomor PLN', placeHolderT: 'Masukkan nomor PLN anda', infoMessageT: 'Silakan masukkan nomor akun Anda'}
            ],
            modalInfo: {
                title: 'PlnCheck Authorization'
            },
            infoMessage: 'To connect, please enter your utility Account number, Customer name and Phone Number',
            infoMessageT: 'Untuk terhubung, masukkan nomor Akun utilitas Anda, nama Pelanggan dan Nomor Telepon',
            partnerPageMainMessageT: 'Kamu akan diminta memberikan akses terhadap PLN anda, untuk dapat membaca:',
            partnerPageHeadingT: 'Hubungkan ke Akun PLN Anda',
            errorMessage: {
                head: 'Error',
                body: 'Unable to process data, please try again!'
            },
            errorMessageT: {
                head: 'Proses Gagal',
                body: 'Koneksi gagal. Coba lagi, ya!'
            },
        },
        {
            code: 'bca-focr',
            name: 'BCA',
            iconM: 'images/BCA.png',
            icon: 'images/BCA_2.png',
            iconWidth: '90px',
            category: 'Bank E-statement Upload',
            //isShowing: partners.find(partner => partner === 'bank-ocr-upload') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'files', value: '', type: 'file', isMultipleFile: true, label: 'Upload Bank E-Statement', placeHolder: 'Upload bank E-Statement', infoMessage: 'Upload Bank E-Statement', labelT: 'Unggah E-Statement bank', placeHolderT: 'Unggah E-Statement bank', infoMessageT: 'Unggah E-Statement bank'},
                { key: 'password', value: '', type: 'password', label: 'PDF password', placeHolder: 'PDF password', infoMessage: 'PDF password', labelT: 'Kata sandi PDF', placeHolderT: 'Kata sandi PDF', infoMessageT: 'Kata sandi PDF', isOptional: true }
            ],
            paramsHandle(params){
                // params['bank_name'] = this.bankCode['BCA'];
                params['bank_name'] = 'bca';
            },
            bankCode: {
                'BCA' : '2'
            },
            modalInfo: {
                title: 'Statement Upload'
            },
            infoMessage: 'Please upload your bank E-Statement',
            infoMessageT: 'Harap unggah E-Statement bank Anda',
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
            isBankingOcr: true,
            ismultipleocr:true
        },
        {
            code: 'mandiri-ocr',
            name: 'Mandiri',
            iconM: 'images/Mandiri.webp',
            icon: 'images/Mandiri_2.png',
            iconWidth: '90px',
            category: 'Bank E-statement Upload',
            //isShowing: partners.find(partner => partner === 'bank-ocr-upload') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'files', value: '', type: 'file', isMultipleFile: true, label: 'Upload Bank E-Statement', placeHolder: 'Upload bank E-Statement', infoMessage: 'Upload Bank E-Statement', labelT: 'Unggah E-Statement bank', placeHolderT: 'Unggah E-Statement bank', infoMessageT: 'Unggah E-Statement bank'},
                { key: 'password', value: '', type: 'password', label: 'PDF password', placeHolder: 'PDF password', infoMessage: 'PDF password', labelT: 'Kata sandi PDF', placeHolderT: 'Kata sandi PDF', infoMessageT: 'Kata sandi PDF', isOptional: true }
            ],
            paramsHandle(params){
                // params['bank_name'] = this.bankCode['BCA'];
                params['bank_name'] = 'mandiri';
            },
            // bankCode: {
            //     'BCA' : '2'
            // },
            modalInfo: {
                title: 'Statement Upload'
            },
            infoMessage: 'Please upload your bank E-Statement',
            infoMessageT: 'Harap unggah E-Statement bank Anda',
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
            isBankingOcr: true,
            ismultipleocr:true
        },
        {
            code: 'bri-ocr',
            name: 'BRI',
            iconM: 'images/BRI.webp',
            icon: 'images/BRI_2.png',
            iconWidth: '90px',
            category: 'Bank E-statement Upload',
            //isShowing: partners.find(partner => partner === 'bank-ocr-upload') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'files', value: '', type: 'file', isMultipleFile: true, label: 'Upload Bank E-Statement', placeHolder: 'Upload bank E-Statement', infoMessage: 'Upload Bank E-Statement', labelT: 'Unggah E-Statement bank', placeHolderT: 'Unggah E-Statement bank', infoMessageT: 'Unggah E-Statement bank'},
                { key: 'password', value: '', type: 'password', label: 'PDF password', placeHolder: 'PDF password', infoMessage: 'PDF password', labelT: 'Kata sandi PDF', placeHolderT: 'Kata sandi PDF', infoMessageT: 'Kata sandi PDF', isOptional: true }
            ],
            paramsHandle(params){
                // params['bank_name'] = this.bankCode['BCA'];
                params['bank_name'] = 'bri';
            },
            // bankCode: {
            //     'BCA' : '2'
            // },
            modalInfo: {
                title: 'Statement Upload'
            },
            infoMessage: 'Please upload your bank E-Statement',
            infoMessageT: 'Harap unggah E-Statement bank Anda',
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
            isBankingOcr: true,
            ismultipleocr:true
        },
        {
            code: 'bni-ocr',
            name: 'BNI',
            iconM: 'images/BNI.webp',
            icon: 'images/BNI_1.png',
            iconWidth: '90px',
            category: 'Bank E-statement Upload',
            //isShowing: partners.find(partner => partner === 'bank-ocr-upload') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'files', value: '', type: 'file', isMultipleFile: true, label: 'Upload Bank E-Statement', placeHolder: 'Upload bank E-Statement', infoMessage: 'Upload Bank E-Statement', labelT: 'Unggah E-Statement bank', placeHolderT: 'Unggah E-Statement bank', infoMessageT: 'Unggah E-Statement bank'},
                { key: 'password', value: '', type: 'password', label: 'PDF password', placeHolder: 'PDF password', infoMessage: 'PDF password', labelT: 'Kata sandi PDF', placeHolderT: 'Kata sandi PDF', infoMessageT: 'Kata sandi PDF', isOptional: true }
            ],
            paramsHandle(params){
                // params['bank_name'] = this.bankCode['BCA'];
                params['bank_name'] = 'bni';
            },
            modalInfo: {
                title: 'Statement Upload'
            },
            infoMessage: 'Please upload your bank E-Statement',
            infoMessageT: 'Harap unggah E-Statement bank Anda',
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
            isBankingOcr: true,
            ismultipleocr:true
        },
        {
            code: 'bca-cocr',
            name: 'BCA',
            iconM: 'images/BCA.png',
            icon: 'images/BCA_2.png',
            iconWidth: '90px',
            category: 'Corporate Bank E-statement Upload',
            isShowing: partners.find(partner => partner === 'bca-ocr') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'files', value: '', type: 'file', isMultipleFile: true, label: 'Upload bank E-Statement', placeHolder: 'Upload bank E-Statement', infoMessage: 'Upload bank E-Statement', labelT: 'Unggah E-Statement bank', placeHolderT: 'Unggah E-Statement bank', infoMessageT: 'Unggah E-Statement bank'},
                { key: 'password', value: '', type: 'password', label: 'PDF password', placeHolder: 'PDF password', infoMessage: 'PDF password', labelT: 'Kata sandi PDF', placeHolderT: 'Kata sandi PDF', infoMessageT: 'Kata sandi PDF', isOptional: true }
            ],
            paramsHandle(params){
                params['bank_name'] = this.bankCode['BCA'];
            },
            bankCode: {
                'BCA' : 'BCA Corporate'
            },
            modalInfo: {
                title: 'Statement Upload'
            },
            infoMessage: 'Please upload your bank E-Statement',
            infoMessageT: 'Harap unggah E-Statement bank Anda',
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
        },
        {
            code: 'bca-pocr',
            name: 'BCA',
            iconM: 'images/BCA.png',
            icon: 'images/BCA_2.png',
            iconWidth: '90px',
            category: 'Retail/Personal Bank E-statement Upload',
            isShowing: partners.find(partner => partner === 'bca-ocr') !== undefined,
            isShowing: false,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'files', value: '', type: 'file', isMultipleFile: true, label: 'Upload bank E-Statement', placeHolder: 'Upload bank E-Statement', infoMessage: 'Upload bank E-Statement', labelT: 'Unggah E-Statement bank', placeHolderT: 'Unggah E-Statement bank', infoMessageT: 'Unggah E-Statement bank'},
                { key: 'password', value: '', type: 'password', label: 'PDF password', placeHolder: 'PDF password', infoMessage: 'PDF password', labelT: 'Kata sandi PDF', placeHolderT: 'Kata sandi PDF', infoMessageT: 'Kata sandi PDF', isOptional: true }
            ],
            paramsHandle(params){
                params['bank_name'] = this.bankCode['BCA'];
            },
            bankCode: {
                'BCA' : 'BCA Personal'
            },
            modalInfo: {
                title: 'Statement Upload'
            },
            infoMessage: 'Please upload your bank E-Statement',
            infoMessageT: 'Harap unggah E-Statement bank Anda',
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
        },
        {
            code: 'multi-bank-ocr',
            name: 'Multi Bank OCR',
            iconM: 'images/multibank.png',
            icon: 'images/bank.png',
            iconWidth: '90px',
            category: 'Bank E-statement Upload',
            isShowing: partners.find(partner => partner === 'bank-ocr-upload') !== undefined,
            //isShowing: false,
            isSelected: false,
            isBeta: false,
            isOnlyProcessAuth: true,
            params: [
                { key: 'files', value: '', type: 'file', isMultipleFile: true, label: 'Upload Bank E-Statement', placeHolder: 'Upload bank E-Statement', infoMessage: 'Upload Bank E-Statement', labelT: 'Unggah E-Statement bank', placeHolderT: 'Unggah E-Statement bank', infoMessageT: 'Unggah E-Statement bank'},
                { key: 'password', value: '', type: 'password', label: 'PDF password', placeHolder: 'PDF password', infoMessage: 'PDF password', labelT: 'Kata sandi PDF', placeHolderT: 'Kata sandi PDF', infoMessageT: 'Kata sandi PDF', isOptional: true }
            ],
            modalInfo: {
                title: 'Statement Upload'
            },
            infoMessage: 'Please upload your bank E-Statement',
            infoMessageT: 'Harap unggah E-Statement bank Anda',
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
            isBankingOcr: true,
            ismultipleocr:true
        },
        {
            code: 'KTP',
            name: 'KTP',
            iconM: 'images/ktp.png',
            icon: 'images/ktp.png',
            iconWidth: '90px',
            category: 'Identity Data',
            isShowing: partners.find(partner => partner === 'identity') !== undefined,
            isSelected: false,
            // isBeta: false,
            // isOnlyProcessAuth: true,
            params: [
                { key: 'files', value: '', type: 'file', isMultipleFile: true, label: 'Upload Bank E-Statement', placeHolder: 'Upload bank E-Statement', infoMessage: 'Upload Bank E-Statement', labelT: 'Unggah E-Statement bank', placeHolderT: 'Unggah E-Statement bank', infoMessageT: 'Unggah E-Statement bank'},
                { key: 'password', value: '', type: 'password', label: 'PDF password', placeHolder: 'PDF password', infoMessage: 'PDF password', labelT: 'Kata sandi PDF', placeHolderT: 'Kata sandi PDF', infoMessageT: 'Kata sandi PDF', isOptional: true }
            ],
            paramsHandle(params){
                params['bank_name'] = this.bankCode['BCA'];
            },
            bankCode: {
                'BCA' : '2'
            },
            modalInfo: {
                title: 'Statement Upload'
            },
            infoMessage: 'Please upload your bank E-Statement',
            infoMessageT: 'Harap unggah E-Statement bank Anda',
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
            isBankingOcr: true,
            ismultipleocr:true
        },
        {
            code: 'NPWP',
            name: 'NPWP',
            iconM: 'images/npwp.png',
            icon: 'images/npwp.png',
            iconWidth: '90px',
            category: 'Identity Data',
            isShowing: partners.find(partner => partner === 'npwp') !== undefined,
            isSelected: false,
            // isBeta: false,
            // isOnlyProcessAuth: true,
            params: [
                { key: 'files', value: '', type: 'file', isMultipleFile: true, label: 'Upload Bank E-Statement', placeHolder: 'Upload bank E-Statement', infoMessage: 'Upload Bank E-Statement', labelT: 'Unggah E-Statement bank', placeHolderT: 'Unggah E-Statement bank', infoMessageT: 'Unggah E-Statement bank'},
                { key: 'password', value: '', type: 'password', label: 'PDF password', placeHolder: 'PDF password', infoMessage: 'PDF password', labelT: 'Kata sandi PDF', placeHolderT: 'Kata sandi PDF', infoMessageT: 'Kata sandi PDF', isOptional: true }
            ],
            paramsHandle(params){
                params['bank_name'] = this.bankCode['BCA'];
            },
            bankCode: {
                'BCA' : '2'
            },
            modalInfo: {
                title: 'Statement Upload'
            },
            infoMessage: 'Please upload your bank E-Statement',
            infoMessageT: 'Harap unggah E-Statement bank Anda',
            partnerPageMainMessageT: '',
            partnerPageHeadingT: '',
            isBankingOcr: true,
            ismultipleocr:true
        },
    ];

    const [eCommerceList, setECommerceList] = useState(initialECommerceList);

    const categories = [ 'Identity Data' , 'Bank E-statement Upload', 'Payment', 'E-Commerce', 'Accounting & POS', 'Corporate Bank E-statement Upload', 'Retail/Personal Bank E-statement Upload', 'Social', 'Utility Bills', 'Retail/Personal Banking', 'Corporate Banking', 'Bank Verification', 'E-Commerce Verification'];

    const categoriesData = {
        'Identity Data': {
            infoMessage: 'Upload any of the relevant Banking platforms E-statement that you have your account in.',
            // translation: 'Platform Unggahan Laporan Bank',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return t('selectPartnerPage.KYC.KTP');
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Transaksi', 'Data Profil', 'Data Akun']
                    : ['Transaction Data', 'Profile Data', 'Account Data'];
            },
            categoryName: 'Identity Data'
        },
        'E-Commerce': {
            infoMessage: 'Connect any of the relevant e-commerce platforms that you have your account in. You can connect more than 1.',
            translation: 'Platform Penjualan',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun Penjual ${partner}`
                    : `${partner} seller Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Transaksi', 'Data Profil', 'Data Metrik']
                    : ['Transaction Data', 'Profile Data', 'Metric Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['ecommerce'],
        },
        'Accounting & POS': {
            infoMessage: 'Connect any of the relevant accounting platforms that you have your account in. You can connect more than 1',
            translation: 'Platform Akuntansi & POS',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun ${partner}`
                    : `${partner} Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Transaksi', 'Profil Perusahaan', 'Data Akun']
                    : ['Transaction Data', 'Company Profile', 'Account Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['accounting'],
        },
        'Payment': {
            infoMessage: 'Connect any of the relevant E-Wallet platforms that you have your account in. You can connect more than 1',
            translation: 'Dompet Digital',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun ${partner}`
                    : `${partner} Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Transaksi', 'Data Profil', 'Data Akun']
                    : ['Transaction Data', 'Profile Data', 'Account Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['payments'],
        },
        'Bank E-statement Upload': {
            infoMessage: 'Upload any of the relevant Banking platforms E-statement that you have your account in.',
            // translation: 'Platform Unggahan Laporan Bank',
            translation: 'Bank E-statement Upload',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `${partner} Bank E-statement Upload`
                    : `${partner} Bank E-statement Upload`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Transaksi', 'Data Profil', 'Data Akun']
                    : ['Transaction Data', 'Profile Data', 'Account Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['bank_estatement'],
        },
        'Corporate Bank E-statement Upload': {
            infoMessage: 'Upload any of the relevant Banking platforms E-statement that you have your account in.',
            translation: 'Platform Unggahan Laporan Bank',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `${partner} Bank E-statement Upload`
                    : `${partner} Bank E-statement Upload`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Transaksi', 'Data Profil', 'Data Akun']
                    : ['Transaction Data', 'Profile Data', 'Account Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['corporate_bank_estatement'],
        },
        'Retail/Personal Bank E-statement Upload': {
            infoMessage: 'Upload any of the relevant Banking platforms E-statement that you have your account in.',
            translation: 'Platform Unggahan Laporan Bank',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `${partner} Bank E-statement Upload`
                    : `${partner} Bank E-statement Upload`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Transaksi', 'Data Profil', 'Data Akun']
                    : ['Transaction Data', 'Profile Data', 'Account Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['retail_bank_estatement'],
        },
        'Social': {
            infoMessage: 'Connect any of the relevant social platforms that you have your account in. You can connect more than 1',
            translation: 'Social Media',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun ${partner}`
                    : `${partner} Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Profil', 'Data Metrik', 'Data Keterlibatan']
                    : ['Profile Data', 'Metric Data', 'Engagement Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['social'],
        },
        'Utility Bills': {
            infoMessage: 'Connect any of the relevant Utility Bills platforms that you have your account in. You can connect more than 1',
            translation: 'Tagihan',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun ${partner}`
                    : `${partner} Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Transaksi', 'Data Akun']
                    : ['Transaction', 'Account Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['not_given'],
        },
        'Retail/Personal Banking': {
            infoMessage: 'Connect any of the relevant Banking platforms that you have your account in. You can connect more than 1',
            translation: 'Internet Banking - Akun Personal',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun ${partner}`
                    : `${partner} Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Transaksi', 'Data Profil', 'Data Akun']
                    : ['Transaction Data', 'Profile Data', 'Account Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['retail_banking'],
        },
        'Corporate Banking': {
            infoMessage: 'Connect any of the relevant Banking platforms that you have your account in. You can connect more than 1',
            translation: 'Internet Banking - Akun Bisnis',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun ${partner}`
                    : `${partner} Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Transaksi', 'Data Profil', 'Data Akun']
                    : ['Transaction Data', 'Profile Data', 'Account Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['corporate_banking'],
        },
        'Bank Verification': {
            infoMessage: 'Connect any of the relevant Banking platforms that you have your account in. You can connect more than 1',
            translation: 'Verifikasi Akun Bank',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred akan menggunakan detail berikut untuk memverifikasi identitas Anda dari bank Anda:`
                    : `PowerCred will use the following details to verify your identity from your bank:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun ${partner}`
                    : `${partner} Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Akun', 'Data Profil']
                    : ['Account Data', 'Profile Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['not_given'],
        },
        'E-Commerce Verification': {
            infoMessage: 'Connect any of the relevant e-commerce platforms that you have your account in. You can connect more than 1.',
            translation: 'Verifikasi Platform Penjualan',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred akan menggunakan detail berikut untuk memverifikasi identitas Anda dari ${partner}:`
                    : `PowerCred will use the following details to verify your identity from ${partner}:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun ${partner}`
                    : `${partner} Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Akun', 'Data Profil']
                    : ['Account Data', 'Profile Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['not_given'],
        },
        'Banking':{
            infoMessage: 'Connect any of the relevant Banking platforms that you have your account in. You can connect more than 1',
            translation: 'Perbankan',
            getText(partner, isTranslated){
                return isTranslated
                    ? `PowerCred dapat mengekstrak data berikut dari akun ${partner} Anda:`
                    : `PowerCred can extract the following data from your ${partner} account:`;
            },
            getHeading(partner, isTranslated){
                return isTranslated
                    ? `Akun ${partner}`
                    : `${partner} Account`;
            },
            getData(code, isTranslated){
                const eCommerce = eCommerceList.find(eCommerce => eCommerce.code == code);
                if(eCommerce.getData){
                    return eCommerce.getData(isTranslated);
                }
                return isTranslated
                    ? ['Data Akun', 'Data Profil']
                    : ['Account Data', 'Profile Data'];
            },
            categoryName: categoryResponse && categoryResponse.categories && categoryResponse.categories['not_given'],
        }
    };

    const headerText = categoryResponse && categoryResponse.headers && categoryResponse.headers['header_text'];
    const searchText = categoryResponse && categoryResponse.headers && categoryResponse.headers['search_text'];

    const verificationStep = appsSettingsResponse && appsSettingsResponse['verification_step'];

    const messagesTranslation = {
        'Please check your email to approve the link and proceed to the next step.': 'Cek email kamu & lakukan verifikasi untuk dapat lanjut ke langkah berikutnya',
        'Please check your account credentials and try again!': 'Harap periksa kredensial akun Anda dan coba lagi!',

        'Shop has been connected to another organization, please disconnect it and re-authorize.': 'Toko telah terhubung ke organisasi lain, harap putuskan sambungannya dan otorisasi ulang.',
        'Shop is not an Official Store or a Power Merchant. Only Official stores or Power Merchants can be authorized.': 'Kamu tidak bisa menghubungkan URL toko tersebut karena bukan toko resmi atau power merchant',
        'Invalid shop url, please check!': 'URL toko tidak valid. Coba lagi, ya!',

        'You have not authorized the verification email, email has been sent again. Please approve the link and proceed to the next step': 'Kamu belum melakukan verifikasi email. Email verifikasi telah dikirim ulang, harap setujui tautan dan lanjutkan ke langkah berikutnya',
        'Email Verification failed': 'Verifikasi email gagal',

        'PDF either edited, or incorrect. Please upload a valid PDF e-statement!': 'PDF salah atau tidak asli. Mohon untuk mengunggah PDF eStatement yang benar!',
        'Please upload a PDF document': 'Mohon mengunggah Dokument PDF',
        'Invalid PDF uploaded': 'Unggahan PDF salah',
        'Incorrect PDF password, please try again!': 'Katasandi PDF salah, Mohon coba kembali!',

        'OTP timeout, please retry after 30 minutes!': 'OTP Timeout, mohon coba kembali dalam 30 menit!',
        'Invalid OTP. Please try again': 'OTP salah. Mohon coba kembali',
        'OTP code is expired. Please resend the code': 'Kode OTP expired. Mohon kirim kode kembali.',
        'Incorrect phone number. Please enter the registered phone number': 'Nomor telepon salah. Mohon masukan nomor yang benar.',
        'Too many attempts. Please try again after 15 minutes': 'Terlalu banyak upaya. Silakan coba lagi setelah 15 menit',
        'Only upto 7 E-Statements are allowed': 'Maksimal 7 E-Statement',
        'Total size of all the files should not exceed 10 MB': 'Ukuran file maksimal 10MB',
        'PDF is password protected, please enter the password and try again!': 'PDF dilindungi kata sandi, masukkan kata sandi dan coba lagi!',
        'Incorrect PDF password, please try again!': 'Kata sandi PDF salah, silakan coba lagi!'
    }

    const isProduction = window.location.href.includes('https://auth.powercred.io')||window.location.href.includes('https://docs.julo.co.id');
    const baseURL = isProduction ? 'https://dev.powercred.io' : 'https://mock.powercred.io';

    const [category, setCategory] = useState({});

    const [selectedPartner, setSelectedPartner] = useState(null);

    const [issubmitted, setIssubmitted] = useState(false)

    const [isShowModal, setIsShowModal] = useState(false);

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const[confirmData, setConfirmData] = useState(null);

    const [showPartnerPage, setShowPartnerPage] = useState(false);

    const [showMessage, setShowMessage] = useState(null);

    const [isLoading, setLoader] = useState(false);

    const [isError, setIsError] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false);

    const [showOTPModal, setShowOTPModal] = useState(false);

    const [pwc, setPwc] = useState(null);

    const [message, setMessage] = useState(null);

    const [title, setTitle] = useState(null);

    const [isSuccess, setIsSuccess] = useState(false);

    const [count, setCount] = useState(0);

    const [key, setKey] = useState(categories[0]);

    const [isShowing, setIsShowing] = useState(false);

    const [isClientHome, setIsClientHome] = useState(false);

    const [isSuccessPage, setIsSuccessPage] = useState(false);

    const [oAuthWindow, setOAuthWindow] = useState(false);

    const [changeData, setChangeData] = useState(false)

    const [fileData, setFileData] = useState([]);

    const [bc, setBc] = useState(new BroadcastChannel("oAuth_channel"));

    const [isOAuthWindowAutoClosed, setIsOAuthWindowAutoClosed] = useState(false);

    const [isToggleVerification, setIsToggleVerification] = useState(false);

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 576);

    const [isShowOnLoaderTop, setIsShowOnLoaderTop] = useState(false);

    // const [openedDropdown, setOpenedDropdown] = useState(null);

    // let openedDropdownvar = false;

    const closeResponse = () => {
        setIsError(false);
        setErrorMessage(false);
    }

    const handleDropdown = (key, bool) => {
        const categoryNew = {...category};
        categoryNew[key] = {...categoryNew[key]};
        Object.keys(categoryNew).forEach(key => {
            categoryNew[key].isShowing = false;
        });
        categoryNew[key].isShowing = bool;
        setCategory(categoryNew);
        // bool && setOpenedDropdown(key);
    };

    const selectPartner = (index, bankIndex) => {
        if(eCommerceList[index].params){
            setSelectedPartner({
                index, bankIndex
            });
            goNext();
        }
        else{
            setSelectedPartner({
                index, bankIndex
            });
            goNext();
            // updateParams(index, [], bankIndex);
        }
    };

    const closeDropdown = () => {
        // if(openedDropdown){
        //     console.log('opened ', openedDropdown);
        //     setOpenedDropdown(null);


        //     setCategory(category => {
        //         const categoryNew = {...category};
        //         categoryNew[openedDropdown] = {...categoryNew[openedDropdown]};
        //         categoryNew[openedDropdown].isShowing = false;
        //         return categoryNew;
        //     });
        // }
    };

    const removePartner = (index, bankIndex) => {
        setConfirmData({
            index, bankIndex
        });
        setShowConfirmModal(true);
    }

    const unSelectPartner = async (index, bankIndex) => {
        if(! verifytoken()){
            return;
        }
        const newECommerceList = [...eCommerceList];
        newECommerceList[index] = {...newECommerceList[index]};
        newECommerceList[index].isSelected = false;

        if(newECommerceList[index].isBanks){
            let indexNew = eCommerceList.indexOf(eCommerceList.find(eCommerce => eCommerce.code == eCommerceList[index].selectedBank));
            let bankIndexNew = eCommerceList[indexNew].banks.indexOf(eCommerceList[indexNew].banks.find(bank => bank.name == eCommerceList[index].name));
            index = indexNew;
            bankIndex = bankIndexNew;
        }

        if(newECommerceList[index].banks){
            if(newECommerceList[index].code == 'bankconnect' || newECommerceList[index].code == 'altconnect'){
                let indexNew = newECommerceList.indexOf(newECommerceList.find(eCommerce => eCommerce.name == newECommerceList[index].banks[bankIndex].name));
                newECommerceList[indexNew].selectedBank = null;
                newECommerceList[indexNew].isSelected = false;
            }
            if(newECommerceList[index].code == 'bankVerification' && bankIndex === undefined){
                bankIndex = newECommerceList[index].banks.indexOf(newECommerceList[index].banks.find(bank => bank.isSelected));
            }
            // newECommerceList[index].banks = {...newECommerceList[index].banks};
            newECommerceList[index].isSelected = false;
            newECommerceList[index].banks[bankIndex].isSelected = false;

            // let indexB = eCommerceList.indexOf(eCommerceList.find(eCommerce => eCommerce.code == newECommerceList[index].banks[bankIndex].code));
            // newECommerceList[indexB] = {...newECommerceList[indexB]};
            // newECommerceList[indexB].isSelected = false;
        }

        setECommerceList(newECommerceList);
        setShowConfirmModal(false);
        setConfirmData(null);

        removeFromLocalStorage(newECommerceList[index].code);

        let bank_name = {};
        if(eCommerceList[index].banks){
            bank_name = {'bank_name': eCommerceList[index].banks[bankIndex].name};
        }
        window.gtag('event', 'partner_removed', { 'partner_name': eCommerceList[index].name, ...bank_name });
    };

    const goNext = () => {
        // if(!selectedPartner){
        //     setShowMessage('Please Select partner');
        //     return;
        // }
        setShowPartnerPage(true);
    }

    const processSuccess = (index, bankIndex, bankCode, result) => {
        const newECommerceList = [...eCommerceList];
        newECommerceList[index] = {...newECommerceList[index]};
        if(newECommerceList[index].isNextStep){
            newECommerceList[index].isNextStepInitiated = true;
            // newECommerceList[index].steps.first.isCompleted = true;
            setECommerceList(newECommerceList);
            return;
        }
        if(newECommerceList[index].isPreviousStep){
            index = newECommerceList.indexOf(newECommerceList.find(eCommerce => eCommerce.code == newECommerceList[index].previousStepCode));
            newECommerceList[index].isNextStepInitiated = false;
        }
        newECommerceList[index].isSelected = true;
        newECommerceList[index].isInitiated = false;
        newECommerceList[index].showOTP = false;
        newECommerceList[index].authCounterVar = 0;
        newECommerceList[index].isResend = false;

        if(newECommerceList[index].banks){
            newECommerceList[index].banks = newECommerceList[index].banks.map(bank => {
                bank.isSelected = bank.code === bankCode;
                return {...bank};
            });
            if(newECommerceList[index].code == 'bankconnect' || newECommerceList[index].code == 'altconnect'){
                let indexB = eCommerceList.indexOf(eCommerceList.find(eCommerce => eCommerce.name == newECommerceList[index].banks[bankIndex].name));
                newECommerceList[indexB] = {...newECommerceList[indexB]};
                newECommerceList[indexB].isSelected = true;
                newECommerceList[indexB].selectedBank = newECommerceList[index].code;
            }
        }
        setECommerceList(newECommerceList);

        // if(!newECommerceList[index].isNextStep){
        //     setIsSuccess(true);
        // }
        setIsSuccess(true);
        setShowPartnerPage(false);
        // setSelectedPartner(null);
        if(document.body.clientWidth > 576){
            selectNextPartner(index, bankIndex);
        }
        saveInLocalStorage(newECommerceList[index].code, bankCode);

        let bank_name = {};
        if(eCommerceList[index].banks){
            bank_name = {'bank_name': eCommerceList[index].banks[bankIndex].name};
        }
        window.gtag('event', 'partner_form_success', { 'partner_name': eCommerceList[index].name, ...bank_name });
    }

    const _selectNextPartner = (index, bankIndex) => {
        let isAfter = false;
        let isGotResult = false;
        let newIndex = undefined, newBankIndex;

        Object.keys(category).forEach(key => {
            category[key].partners.forEach(indexI => {
                if(isGotResult){
                    return;
                }
                if((indexI == index && !eCommerceList[index].banks) || (indexI == index && eCommerceList[index].code == 'bankVerification')){
                    isAfter = true;
                    return;
                }
                if(eCommerceList[index].isShowing && (!eCommerceList[index].isSelected || (eCommerceList[index].banks && eCommerceList[index].code !== 'bankVerification'))){
                    newIndex = indexI;
                    if(eCommerceList[index].banks && eCommerceList[index].code !== 'bankVerification'){
                        eCommerceList[index].banks.forEach((bank, bankIndexI) => {
                            if(isGotResult){
                                return;
                            }
                            if(indexI == index && bankIndexI == bankIndex){
                                isAfter = true;
                                return;
                            }
                            if(!bank.isSelected){
                                newBankIndex = bankIndexI;
                                if(isAfter){
                                    isGotResult = true;
                                }
                            }
                        });
                    }
                    if(isAfter){
                        isGotResult = true;
                    }
                }
            });
        });

        if(newIndex !== undefined){
            setSelectedPartner({'index': newIndex, 'bankIndex': newBankIndex}); 
            console.log(newIndex, newBankIndex);
        }
    };

    const selectNextPartner = (index, bankIndex, isInitial) => {
        let isAfter = isInitial;
        let isGotResult = false;
        let newIndex = undefined, newBankIndex = undefined;

        categories.filter(key => key in category).map(key => {
            // Object.keys(category).forEach(key => {
            category[key].partners.forEach(indexI => {
                // console.log(eCommerceList[indexI].code);
                if(isGotResult){
                    return;
                }
                if(indexI == index){
                    isAfter = true;
                    return;
                }
                if(eCommerceList[indexI].isShowing && !eCommerceList[indexI].isSelected){
                    // console.log(indexI, eCommerceList[indexI].isSelected);
                    newIndex = indexI;
                    if(eCommerceList[indexI].banks && eCommerceList[indexI].code !== 'bankVerification'){
                        newBankIndex = 0;
                    }
                    if(isAfter){
                        isGotResult = true;
                    }
                }
            });
        });

        if(newIndex !== undefined){
            setSelectedPartner({'index': newIndex, 'bankIndex': newBankIndex}); 
            // console.log(newIndex, newBankIndex);
        }

    };

    const showError = message => {
        setIsError(true);
        setErrorMessage({'body': message}); 
    }


    const updateParams = async (index, params, bankIndex) => {
        // processSuccess(index, bankIndex); return;
        if(! verifytoken()){
            return;
        }
        let bankCode;
        const paramsO = params;
        // console.log(index, params, bankIndex);

        if(!isMobileView && eCommerceList[index].code == 'bankVerification'){
            let bankName = params.find(param => param.key == 'bank').value;
            bankIndex = eCommerceList[index].banks.indexOf(eCommerceList[index].banks.find(bank => bank.name == bankName));
            // const bank = eCommerceList[index]._banks.find(bank => bank.name == bankName);
            // bankCode = bank.code;
            // eCommerceList[index].data = bank.data;
        }
        if(eCommerceList[index].isBanks){
            let indexNew;
            if(params.find(param => param.key == 'bankType').value == 'Retail/Personal Banking'){
                indexNew = eCommerceList.indexOf(eCommerceList.find(eCommerce => eCommerce.code == 'bankconnect'));
            }
            else{
                indexNew = eCommerceList.indexOf(eCommerceList.find(eCommerce => eCommerce.code == 'altconnect'));
            }
            let bankIndexNew = eCommerceList[indexNew].banks.indexOf(eCommerceList[indexNew].banks.find(bank => bank.name == eCommerceList[index].name));
            // console.log(eCommerceList[index].name , eCommerceList[indexNew].name, eCommerceList[indexNew].banks[bankIndexNew].name, params);
            index = indexNew;
            bankIndex = bankIndexNew;
            // console.log(index, bankIndex);
            // return;
        }
        if(eCommerceList[index].banks){
            bankCode = eCommerceList[index].banks[bankIndex].code;
            eCommerceList[index].data = eCommerceList[index].banks[bankIndex].data;
        }

        if (eCommerceList[index].isInitiated && !eCommerceList[index].authCounter) {
            setLoader(true);
            getResponse(eCommerceList[index].code, index, bankCode);
            return;
        }

        if (isUserOnboardingDemo) {
            processSuccess(index, bankIndex, bankCode, {});
            return;
        }

        params = params.reduce((prev, curr) => {
            prev[curr.key] = curr.value;
            return prev;
        }, {});
        eCommerceList[index].paramsHandle && eCommerceList[index].paramsHandle(params);

        if (eCommerceList[index].showMessageBeforeRequest) {
            setShowMessage(isTranslated ? eCommerceList[index].showMessageBeforeRequestT : eCommerceList[index].showMessageBeforeRequest);
            setTitle('');
            setIsShowOnLoaderTop(true);
        }

        setLoader(true);
        const result = await pwc.setParameters(eCommerceList[index].code, params, eCommerceList[index].data, bankCode);
        if (Array.isArray(result.isSuccess)){
            setFileData(result.isSuccess)
            setIsShowModal(true);
            setIsSuccess(false)
        }else{
            setChangeData(true);
        }
        
        setLoader(false);

        // console.log(result);
        if (result.isSuccess) {
            if (eCommerceList[index].isOTP) {
                // setShowOTPModal({index, eCommerce: eCommerceList[index]});
                const newECommerceList = [...eCommerceList];
                newECommerceList[index] = { ...newECommerceList[index] };
                newECommerceList[index].params = [...paramsO];
                newECommerceList[index].showOTP = true;
                setECommerceList(newECommerceList);
            }
            else {
                // setLoader(true);
                // const result = await pwc.getProfileData(eCommerceList[index].code, true);
                // setLoader(false);
                // processSuccess(index, bankIndex, bankCode, result);
                // setTimeout(() => {
                //     if(unprocessedCountVar.length){
                //         setLoader(false);
                //         setIsError(true);
                //             setIsTimeOut(true);
                //             isTimeOutVar = true;
                //         eCommerceList[index].errorMessage && setErrorMessage(eCommerceList[index].errorMessage);
                //     }
                // }, 200000);
                if (eCommerceList[index].isOAuth) {
                    setOAuthWindow(result.oAuthWindow);
                    const oAuthWindowCheck = setInterval(() => {
                        if (result.oAuthWindow.closed) {
                            clearInterval(oAuthWindowCheck);
                            setIsOAuthWindowAutoClosed(isOAuthWindowAutoClosed => {
                                if (!isOAuthWindowAutoClosed) {
                                    setLoader(false);
                                    setIsError(true);
                                    setErrorMessage({ body: isTranslated ? 'Proses belum selesai, Silakan coba lagi!' : 'Process not completed, Please try again!' });
                                    // processSuccess(index); console.log('processed', index, selectedPartner);//
                                    // setSelectedPartner({...selectedPartner}); //
                                }
                                return false;
                            });
                        }
                    }, 1000);
                    setLoader(true);

                    // setShowMessage(isTranslated ? 'Silakan Otorisasi dan kemudian klik tombol NEXT' : 'Please Authorize and then click on the NEXT button');

                    // const newECommerceList = [...eCommerceList];
                    // newECommerceList[index] = {...newECommerceList[index]};
                    // newECommerceList[index].isInitiated = true;
                    // setECommerceList(newECommerceList);
                }
                // else if(eCommerceList[index].isPreviousStep){
                //     // const previousStepIndex = eCommerceList.indexOf(eCommerceList.find(eCommerce => eCommerce.code == eCommerceList[index].previousStepCode));
                //     // processSuccess(previousStepIndex);
                //     processSuccess(index);
                // }
                else if (result.showMessage) {
                    let message = 'A verification email has been sent to your email address, Please authorize it and then click on NEXT button';
                    const newECommerceList = [...eCommerceList];
                    newECommerceList[index] = { ...newECommerceList[index] };
                    newECommerceList[index].isInitiated = true;

                    if (newECommerceList[index].authCounter) {
                        newECommerceList[index].params = [...paramsO];
                        let resultAuthCounter = newECommerceList[index].authCounter();
                        if (resultAuthCounter.error) {
                            newECommerceList[index].isInitiated = false;
                            newECommerceList[index].authCounterVar = 0;
                            setShowPartnerPage(false);
                            // setSelectedPartner(null);
                            setIsError(true);
                            setErrorMessage({ body: isTranslated && messagesTranslation[resultAuthCounter.error] ? messagesTranslation[resultAuthCounter.error] : resultAuthCounter.error });
                        }
                        else {
                            setShowMessage(isTranslated && messagesTranslation[resultAuthCounter.showMessage] ? messagesTranslation[resultAuthCounter.showMessage] : resultAuthCounter.showMessage);
                            setTitle('');
                        }
                    }
                    else {
                        setShowMessage(isTranslated && messagesTranslation[result.showMessage] ? messagesTranslation[result.showMessage] : result.showMessage);
                        setTitle('');
                    }

                    setECommerceList(newECommerceList);
                }
                else {
                    if (eCommerceList[index].isOnlyProcessAuth) {
                        // console.log(eCommerceList[index].code, ' success'); 
                        processSuccess(index, bankIndex, bankCode, {});
                    }
                    else {
                        setLoader(true);
                        getResponse(eCommerceList[index].code, index, bankCode);
                    }
                }
                // setTimeout(() => {
                //     getResponse(eCommerceList[index].code, index, bankCode);
                // }, 10000);
            }
        }
        else {
            // const newECommerceList = [...eCommerceList];
            // newECommerceList[index] = {...newECommerceList[index]};
            // newECommerceList[index].isInitiated = false;
            // newECommerceList[index].authCounterVar = 0;
            // setECommerceList(newECommerceList);
            setIsError(true);
            eCommerceList[index].errorMessage && setErrorMessage(isTranslated ? eCommerceList[index].errorMessageT : eCommerceList[index].errorMessage);
            result.error.showMessage && setErrorMessage({ body: isTranslated && messagesTranslation[result.error.showMessage] ? messagesTranslation[result.error.showMessage] : result.error.showMessage });
        }
    }

    const closeInfoMessage = () => {
        setShowMessage(null);
        setIsShowOnLoaderTop(false);
    }

    const getResponse = async (code, index, bankCode, count = 0) => {
        // let lcount = count;
        if (!verifytoken()) {
            return;
        }
        console.log(code, ' called', ++count);

        // setLoader(true);
        const response = await pwc.getProfileData(code, true);
        // setLoader(false);

        if (!response.isError) {
            // const widgetResponseNew = {...widgetResponseVar};
            // widgetResponseNew.gateway_res[code] = response.data;
            // setWidgetResponse(widgetResponseNew);
            // widgetResponseVar = widgetResponseNew;
            // const index = unprocessedCountVar.indexOf(unprocessedCountVar.find(partner => partner == code));
            // unprocessedCountVar.splice(index, 1);
            // setUnprocessedCount(unprocessedCountVar);
            // if(!unprocessedCountVar.length){
            //     success(widgetResponseNew);
            // }

            // setCount(0);
            setLoader(false);
            // console.log(code, ' success', response); //, widgetResponseNew, unprocessedCountVar);
            processSuccess(index, undefined, bankCode, response);
            closeInfoMessage();
        }
        else if (response.isError || count > 10) {

            // setCount(0);
            setLoader(false);
            console.log(code, ' error');
            setIsError(true);
            eCommerceList[index].errorMessage && setErrorMessage(isTranslated ? eCommerceList[index].errorMessageT : eCommerceList[index].errorMessage);

            const newECommerceList = [...eCommerceList];
            newECommerceList[index] = { ...newECommerceList[index] };
            newECommerceList[index].isInitiated = false;
            newECommerceList[index].showOTP = false;
            setECommerceList(newECommerceList);

            closeInfoMessage();
        }
        else if (true) {
            setTimeout(() => getResponse(code, index, bankCode, count), 10000);
            // setCount(lcount);
        }
    };

    const updateParamsOTP = async (index, params) => {
        if (!verifytoken()) {
            return;
        }
        params = params.reduce((prev, curr) => {
            prev[curr.key] = curr.value;
            return prev;
        }, {});

        setLoader(true);
        const result = await pwc.setOtp(eCommerceList[index].code, params);
        setLoader(false);

        if (result.isSuccess) {
            if (eCommerceList[index].isOnlyProcessAuth) {
                processSuccess(index, undefined, undefined, result);
            }
            else {
                setLoader(true);
                getResponse(eCommerceList[index].code, index);
            }
        }
        else {
            setLoader(false);
            setIsError(true);
            setErrorMessage({
                head: '',
                body: isTranslated ? 'Otentikasi OTP gagal!' : 'OTP Authentication failed!'
            });

            result.error.showMessage && setErrorMessage({ head: '', body: isTranslated && messagesTranslation[result.error.showMessage] ? messagesTranslation[result.error.showMessage] : result.error.showMessage });

            const newECommerceList = [...eCommerceList];
            newECommerceList[index] = { ...newECommerceList[index] };
            // newECommerceList[index].showOTP = false;
            newECommerceList[index].isResend = true;
            setECommerceList(newECommerceList);
        }
    }

    const createPWCObject = () => {
        const apiKey = client_id;
        const pwc = new PWC({
            apiKey,
            id: idT,
            callback: (companyID) => {
                // window.gtag('config', 'G-0WQWYRSVZK', {
                //     'user_id': companyID
                // });
            }
        });
        setPwc(pwc);
        const apiKeyObj = {
            'Cmvog9nUcRPtFTJlczrGKVAHe8oBftTAQNq5kbZjYRiUlkRV': 'bnc',
            'iiV0pirSZpnKudtQX4MCrNgAHbUTAnOwOSQM4BOfVrfwUqyp': 'sandbox',
            '2Q9xJ4G4LyPAb1sbgz8srSUeaGSMu8du2burNWj8tMfOhUuX': 'finku',
            'kl4wA1TAmZHPTMGtaCE8n4EyifZ4KkKxaMt8rMIvEPLMBqc3': 'djoin',
            'YdLkX3PmIX7PjeIQdYNkj7i1WYWaXcm7RK6xVhxmB49enrAC': 'KW-IL',
            'oAcrhKq2ibD6TIyLEcTpj1CDsCsAQIURm3GMm8vPpbwbNmQp': 'akseleran productive',
            'hCYq8W1CY7OhmcrsYyqmwktXEit7kZityarqassZUAAtfxiH': 'akseleran consumptive',
            'h8JldQovfaVJewIbxugKTqOVH4pckoUkq9VcCDtazuPgBGLS': 'julo',
            'qSVVoCD5JltQSmWdbKRVgFDybZ025LU0': 'getmo',
            '1lsrgN6t0kgCGZMzX1UIwTslAEYpEjfTfNqDGmC517DQfrHJ': 'oto',
            'OaPvxBVKMx1q8LazyTzRFnDRCyr6Latfa3ZFUzp7wZ5Snep4': 'skbf-fleet',
            'BMMucXWtPGE2lfMzxBHL22PQs6XfrmT10nZOCVPYOCcB75hr': 'skbf-retail',
            'qTdmH558T9gbsTAwiTJutzssDFMbZ7hDGDL3jWBwnjQ4S16Z': 'ojk',
            '26rACXFTtLjlrjihY85kx0zFMrvIlHA0HVulQlMbKGULanJg': 'semaai',
            'laLAwpAWC37bNPLToBNyNkZeEkPSeKJHfxQHOPApnkdBJlBP': 'gandengtangan',
            '7YN8BWX9uNB6fgHOYLaTNRN8ueHeXb12bE7sDQd8xPS1W9RP': 'akseleran ecommerce',
            'a7VsRAW3g13sAoCuSkfMI3vmKE1vGG1UWrjBIWAXa6wyATAW': 'danasyariah',
            '8ScI2X6ynbGOnTlqh5YAkwtNeyZpqAapszPe4lAGdGmdAHEu': 'komunal',
            'CsMc3mrrJ1GGTABffZr8iz86qq0WaoL43nBWUolw1li0I4N0': 'sumsub',
            'ZRCRR9Eo92iYE6KqPUjk6n1p7b6pHZXXIKpo0E2WabPSDg3l': 'aktivaku',
            'iZ1gYY5fcHeN4JZPnxbW9JEpiL1csZ1BYT5lBdgeMyqAGHAR': 'saison',
            '1U6hAe8YEGZVoqdDavD9ADx6xcCm6YvShZz14zEzmyOLD2yB': 'muf',
            'Ff2XUdnwDAFVwRuGjpeGw0wRnXpcDKJ25Hp7JJVxpGsuJTQQ': 'credable',
            'jG9aOIDec21qfd5b2975Sz3J7C20mRHQvTU1S8io4YER4bin': 'fs',
            'AuJyLAMXqsdif7lnoy2TJZLBKMovZJF9GPqrLIHAMVERXj70': 'myboost-indo',
            'g5PSMLwHV2tl6c2aWI8HNoMqAHq0GbJ2DLqtSwl8ejMB2TWS': 'maucash',
            'ckeqG0yGGT48P0F0d2WPcYkJTqijr9xvtC621OYE72MAAoAr': 'mtf-fleet',
            'gWeVzxpllSVeu8Tcl69A4AEmcY2WerO5yTpt03yKjW9JNVZA': 'oto-consumer',
            '5w4trAKZQoRJo13DYyFqQUaTlenhsXF52tY5VflHY58amLyX': 'mtf-retail',
            'G7EZqEGV0suLxkvaMpcBKjWN7XkV6OA3ac2CpIxnSChYeWvq': 'gajigesa',
            'A7dMUJcSAAuEfoRR6x67lGST1UAMoGPujBaEwhFqkh82ofq1': 'batumbu1',
            'Cs5Gh0ZEBEhjbbxGGsNkCUe4cHkJFjUisA5u1X8XtAHcq2FU': 'batumbu2',
            'z7HtoUFcXEqwCTJRzoJ2zjkGH3ZEuHaiJgg5J1Q3w5GaCCZD': 'batumbu3',
            'VvlWGQHaBJTGOFNDLSfJttY6ZYeERpDF0lBXuLfbxH0xDugC': 'batumbu4',
            'Nrmz0AO8XRwyFIq6Mc2cr4oTyXBXqGqTkgv9W5AVguqkNS6a': 'batumbu5',
            '7BbeV94DtYSD9mMLFpyziF8MCC4dlUIzlYZnLfeTL6Qya3oR': 'batumbu6',
            'i0x7JhAQvhsYlYShnet4OZVzMRG1oMdzfZPb8IyDkryXo9q5': 'moladin',
            '3fcZTpYlBaG6pGrAkKwAsYaIqbti8x4qXHmNE7TA8Ugr58yI': 'kredivo-poc',
            'm9DxeRbHkzwDtcd5YpP33ZL7Y2Nf3WCvvk0hT0I3UnutIy1P': 'cimb',
            'lr2LkC6LmvCUb5SvvOqmwHZQqfBKc5sZsMUDjMc3FuHqbn93': 'arthaprimafinance',
            'qWZtdmAaznW7ewseSLTQq2uyEN5uNPR42yBhcWeFtW9TaV1v': 'astrapay',
            '9CGoHurAP9YBv2sv13IUvLAjZeZp7SHpG7GBTmHdoVAI9VGE': 'buanafinance',
            'GAetU5eOFE66hfBGYVJInPFH5NlEHhIPR8uiEMRv3kWEhE9d': 'koinworks',
            '43YwY2cvMxdilNYfEb1SD2qof4wKbGrEzhsHaULMYbl4Txh9': 'ajaib',
            'WAGgmc8MT2o5zFWFyMyxpBtR7YeTOCyurL0yKWZuHkPZroo0': 'finfra',
            '3r3K4ALP0sxr3BZsMAEKiDmxJqGxO8gfA3Rl7BbB1Ipjyfuy': 'bnifinance',
            'j8xbvAAeOsjuJa85B0s704vjmxgnRf89jz7OtvxMqkpeQizS': 'valdo',
            'zhS0WHRl1vDR7yPW60jkHs7bGk5ruRdEPKoRBkTe5nuj2bA7': 'bukuwarung',
            'TVbmrJP4yjONnXNTQFSAngR3TN5647Xwbo1Ys4sG4iyIv5ZY': 'trustdecision',
            'oO8A4GgadeTQBGg6iqClyWW9WcHYGrRNg64OAKzh2NYvqdLJ': 'hana',
            'CkbG6lNuxBTlTx13fvIuMGqzgE2k1mYBIw9rsxA0LgN8Ir0A': 'nikel',
            'UmQj1bIz3CHDD5Xf0dkVOvRPzpOvkBiYUktgaCT7IXV97Y4G': 'amartha',
            'MnmpuuUmLdC01ICbEvr9SNXHHP3HptAGHKGweE8e7weGGYi6': 'bankba'
        }
        window.gtag('config', 'G-0WQWYRSVZK', {
            'user_id': idT,
            'client': apiKeyObj[apiKey]
        });
    };

    const verifytoken = async () => {
        const config = {
            method: 'post',
            url: `/auth/token/verify?apikey=${client_id}&client_id=${client_id}&access_token=${token}`,
            baseURL,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
        let error;
        let response;
        try {
            response = await axios(config);
            // console.log(response.data);
            if (response.data.status == 'success') {
                error = false;
            }
            else {
                error = true;
            }
        }
        catch (e) {
            console.log('error ', e);
            error = true;
        }

        if (!error) {
            return true;
        }
        else {
            setHomeWrongToken();
            return false;
        }
    };

    const searchPartner = e => {
        // console.log(e.target.value);
        // return;
        const filter = e.target.value.toUpperCase();

        const ul = document.getElementsByClassName("search-partners-container")[0];
        const li = ul.getElementsByClassName("search-partner");
        for (let i = 0; i < li.length; i++) {
            const a = li[i].getElementsByTagName("span")[0];
            const txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }

    const showPartners = e => {
        // console.log('asd');
        document.getElementsByClassName("search-partners-container")[0].style.display = 'block';
        // document.getElementsByClassName('search-partners-background')[0].style.display = 'block';

        const categoryNew = category;
        Object.keys(category).forEach(key => {
            categoryNew[key].isShowing = false;
            categoryNew[key] = { ...categoryNew[key] };
        })
        setCategory({ ...categoryNew });
        e.stopPropagation();
    };

    const hidePartners = e => {
        const div = document.getElementsByClassName("search-partners-container");
        if (div.length) {
            div[0].style.display = 'none';
        }
        // document.getElementsByClassName('search-partners-background')[0].style.display = 'none';
    }

    const showPartner = (eCommerce, bankIndex) => {
        if (document.body.clientWidth <= 576) {
            if (!eCommerce.isSelected) {
                setIsShowing(true);
            }

            // handleDropdown(eCommerce.category, true); 


            let element;

            if ((!eCommerce.banks && eCommerce.isSelected) || (eCommerce.banks && eCommerce.banks[bankIndex].isSelected)) {
                setKey('connected');
                element = document.getElementById('connected-title');
            }
            else {
                setKey(eCommerce.category);
                element = document.getElementById(eCommerce.category + '-title');
            }


            element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });


            // if(bankCode){
            //     document.getElementById(bankCode).scrollIntoView();
            // }
            // else{
            //     document.getElementById(eCommerce.code).scrollIntoView();
            // }

            document.getElementsByClassName("search-partners-container")[0].style.display = 'none';
        }
        else {
            handleDropdown(eCommerce.category, true);
            document.getElementById(eCommerce.category).scrollIntoView();
            document.getElementsByClassName("search-partners-container")[0].style.display = 'none';
        }
    }

    const partnerClicked = (index, bankIndex) => {
        // console.log('clicked --- ', index, bankIndex); return;
        if ((eCommerceList[index].isSelected && !eCommerceList[index].banks) || (eCommerceList[index].banks && ((eCommerceList[index].isSelected && bankIndex === undefined) || (bankIndex !== undefined && eCommerceList[index].banks[bankIndex].isSelected)))) {
            // removePartner(index, bankIndex);

            return;
        }
        // console.log('clicked --- ');
        // handleDropdown(key, false); 
        selectPartner(index, bankIndex);

        cancelProcess();
        // let bank_name = {};
        // if(eCommerceList[index].banks){
        //     bank_name = {'bank_name': eCommerceList[index].banks[bankIndex].name};
        // }
        // window.gtag('event', 'partner_clicked', { 'partner_name': eCommerceList[index].name, ...bank_name }); 
    }

    const tooglePartners = (isToogle) => {
        if (isToogle == isToggleVerification) {
            return;
        }
        const newECommerceList = eCommerceList.map(eCommerce => {
            if (!eCommerce.isShowing || eCommerce.code == 'altconnect' || eCommerce.code == 'bankconnect') {
                return eCommerce;
            }
            if (isToogle) {
                eCommerce.isHidden = eCommerce.isVerification ? false : true;
            }
            else {
                eCommerce.isHidden = !eCommerce.isVerification ? false : true;
            }
            return { ...eCommerce };
        })
        setECommerceList(newECommerceList);
        setIsToggleVerification(isToogle);
    }

    const cancelProcess = () => {
        setShowPartnerPage(false);
        const newECommerceList = [...eCommerceList];
        newECommerceList[selectedPartner.index] = { ...newECommerceList[selectedPartner.index] };
        newECommerceList[selectedPartner.index].isInitiated = false;
        newECommerceList[selectedPartner.index].authCounterVar = 0;
        setECommerceList(newECommerceList);
    };

    const handleWindowSizeChange = () => {
        setIsMobileView(window.innerWidth <= 576 ? true : false);
    };

    const saveInLocalStorage = (code, bankCode) => {
        let connectedPartners = localStorage.getItem('connectedPartners');
        if (connectedPartners == null) {
            connectedPartners = '{}'
        }
        connectedPartners = JSON.parse(connectedPartners);
        connectedPartners[code] = { bankCode };
        localStorage.setItem('connectedPartners', JSON.stringify(connectedPartners));
    };

    const removeFromLocalStorage = (code) => {
        let connectedPartners = localStorage.getItem('connectedPartners');
        if (connectedPartners == null) {
            connectedPartners = '{}'
        }
        connectedPartners = JSON.parse(connectedPartners);
        delete connectedPartners[code];
        localStorage.setItem('connectedPartners', JSON.stringify(connectedPartners));
    };

    const updateFromLocalStorage = () => {
        let connectedPartners = localStorage.getItem('connectedPartners');
        if (connectedPartners == null) {
            connectedPartners = '{}'
        }
        connectedPartners = JSON.parse(connectedPartners);

        const newECommerceList = [...eCommerceList];
        Object.keys(connectedPartners).forEach(key => {
            const index = newECommerceList.indexOf(newECommerceList.find(eCommerce => eCommerce.code == key));
            if (index != -1) {
                newECommerceList[index] = { ...newECommerceList[index] };
                newECommerceList[index].isSelected = true;
                if (newECommerceList[index].banks) {
                    const bankIndex = newECommerceList[index].banks.indexOf(newECommerceList[index].banks.find(bank => bank.code == connectedPartners[key].bankCode));
                    if (bankIndex != -1) {
                        newECommerceList[index].banks[bankIndex].isSelected = true;
                        if (newECommerceList[index].code == 'bankconnect' || newECommerceList[index].code == 'altconnect') {
                            let indexB = newECommerceList.indexOf(newECommerceList.find(eCommerce => eCommerce.name == newECommerceList[index].banks[bankIndex].name));
                            newECommerceList[indexB] = { ...newECommerceList[indexB] };
                            newECommerceList[indexB].isSelected = true;
                            newECommerceList[indexB].selectedBank = newECommerceList[index].code;
                        }
                    }
                    else {
                        newECommerceList[index].isSelected = false;
                    }
                }
            }
        });
        setECommerceList(newECommerceList);
    };

    // const translate = (lang) => {
    //     let ind = 1;
    //     if(lang == 'en'){
    //         if(!isTranslated){  //document.getElementsByClassName('goog-te-combo')[0].options[0].value == ''){
    //             return;
    //         }
    //         ind = 0;
    //     }
    //     else if(isTranslated){
    //         return;
    //     }
    //     setIsTranslated(ind ? true : false);

    //     document.getElementsByClassName('goog-te-combo')[0].options[ind].selected = true;
    //     var evt = document.createEvent("HTMLEvents");
    //     evt.initEvent('change', true, true ); // event type,bubbling,cancelable
    //     return !document.getElementsByClassName('goog-te-combo')[0].options[ind].dispatchEvent(evt);

    // };

    const broadcastCallback = (event) => {
        const index = selectedPartner.index;
        oAuthWindow.close();
        setIsOAuthWindowAutoClosed(true);
        setLoader(false);

        if (event.data == 'callback-process-success') {
            processSuccess(index);
        }
        else if (event.data == 'callback-process-fail') {
            setIsError(true);
            setErrorMessage({ body: isTranslated ? 'Kesalahan dalam Otentikasi, Silakan coba lagi!' : 'Error in Authentication, Please try again!' });
            eCommerceList[index].errorMessage && setErrorMessage(isTranslated ? eCommerceList[index].errorMessageT : eCommerceList[index].errorMessage);
        }
    };

    bc.onmessage = broadcastCallback;

    useEffect(() => {
        createPWCObject();

        const category = eCommerceList.filter(eCommerce => eCommerce.isShowing).reduce((prev, eCommerce) => {
            if (eCommerce.category in prev) {
                prev[eCommerce.category].partners.push(eCommerceList.indexOf(eCommerce));
            }
            else {
                prev[eCommerce.category] = {
                    isShowing: false,
                    partners: [eCommerceList.indexOf(eCommerce)]
                };
            }
            return prev;
        }, {});
        setCategory(category);

        setKey(categories.find(key => key in category) || 'connected');

        document.getElementsByTagName('body')[0].addEventListener('click', e => {
            // console.log('qwe');
            hidePartners(e);
        });
        // selectNextPartner(undefined, undefined, true);

        let isFind;
        categories.filter(key => key in category).map(key => {
            category[key].partners.map(index => {
                if (!isFind) {
                    setSelectedPartner({
                        index, bankIndex: 0
                    });
                    isFind = true;
                }
            });
        })
        // setSelectedPartner({
        //     index: 0, bankIndex: 0
        // });

        updateFromLocalStorage();
        window.addEventListener('resize', handleWindowSizeChange);
    }, []);

    const translateHandler = (lang) => {
        i18n.changeLanguage(lang);
        translate(lang)
    }

    async function callUploadAPI(key, value) {
        try {
            let data = new FormData();
            data.append('files', key);
            const config = {
                method: 'POST',
                url: '/bank/analysis/stmt/upload',
                baseURL,
                params: {
                    id: idT,
                    apikey: client_id,
                    bankname: value,
                },
                data: data
            };

            const response = await axios(config);
            // console.log('Upload API response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error calling the Upload API:', error);
            throw error;
        }
    }

    async function callPublishAPI() {
        try {

            const config = {
                method: 'POST',
                url: '/bank/analysis/stmt/publish',
                baseURL,
                params: {
                    id: idT,
                    apikey: client_id,
                }
            };
            const response = await axios(config);
            // console.log('publish API response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error calling the publish API:', error);
            throw error;
        }
    }


    const submitBtn = async (filelist) => {
        try {
            setLoader(true);
          const uploadPromises = Object.keys(filelist).map(async (key) => {
            try {
              return await callUploadAPI(filelist[key].fileName, filelist[key].bank);
            } catch (error) {
              console.log('error calling callUploadAPI');
              return null; 
            }
          });
      
          // Wait for all upload requests to complete
          const uploadResponses = await Promise.all(uploadPromises);
         
          // Check if all uploads were successful
          const allUploadsSuccessful = uploadResponses.every((response) => response !== null);
      
          if (allUploadsSuccessful) {
            // All uploads successful, proceed with second API call
            try {
              const secondResponse = await callPublishAPI();
              setLoader(false);
              // Second API call successful, update the state
              setFileData([]);
              setIssubmitted(true);
            } catch (error) {
              console.log('error calling callPublishAPI');
            }
          } else {
            console.log('Some file uploads failed, skipping second API call');
          }
        } catch (error) {
          console.log('Error during file upload or publishing:', error);
        }
      };
      


    // if(showPartnerPage){
    //     return <PartnerPage eCommerceList={eCommerceList} index={selectedPartner.index} bankIndex={selectedPartner.bankIndex} updateParams={updateParams} back={() => setShowPartnerPage(false)}/>;
    // }
    // if(isSuccess){
    //     return <Success back={() => setIsSuccess(false)}/>
    // }
    return (
        <div onClick={closeDropdown}>
            <div>
                <nav className="home-click" onClick={() => setShowPartnerPage(null)}>
                    {/* <div className="container"> */}
                    <img src={logoResponse ? logoResponse : require('../components/images/images/powercred_logo_2.png')} alt="powercred_logo" height="60" className='pwc-logo' />
                    {/* <div className="navbar-brand" id="google_translate_element_i"></div> */}
                    {/* </div> */}
                    {/* <img src={require('../components/images/images/powercred_logo_3(1).png')} alt="powercred_logo" height="60" className='pwc-logo' /> */}

                </nav>
                {/* <div className='menu-icon'>
                    <div className='menu-icon-row'></div>
                    <div className='menu-icon-row'></div>
                    <div className='menu-icon-row'></div>
                </div> */}
                <div className="google-translate">
                    <div className={`notranslate google-translate-btn google-translate-en ${!isTranslated ? 'google-translate-btn-selected' : ''}`} onClick={() => translateHandler('en')}> EN </div>
                    <div className={`notranslate google-translate-btn ${isTranslated ? 'google-translate-btn-selected' : ''}`} onClick={() => translateHandler('id')}> ID </div>
                </div>
            </div>
            {
                isMobileView && isJulo && (
                    (<div className="partner-page-n"><MultiBanking clientJulo={isJulo} onFormSuccess={()=>{console.log("True")}} /></div>)
                )
            }
            {
                !showPartnerPage && isMobileView && !isJulo && (
                    <>
                        <div className="Partner-class-nui">
                            <h3 className="Partner-heading1-nui">
                                {
                                    headerText
                                        ? headerText
                                        : (isTranslated ? 'Pilih Akun' : 'Choose your account')
                                }
                            </h3>

                            <div className="search-container-div search-container-div-p2">
                                <div className="search-container">
                                    <form action="/action_page.php">
                                        <input type="text" placeholder={searchText ? searchText : (isTranslated ? "Pilih Mitra Anda" : "Choose your Partners")} name="search" onKeyUp={searchPartner} onClick={showPartners} className='searchbar-input' />
                                        {/* <button type="submit"><i className="fa fa-search"></i></button> */}
                                    </form>
                                    {/* <div className='search-partners-background' style={{display: 'none'}} onClick={hidePartners}></div> */}
                                    <div className='search-partners-container' style={{ zIndex: '100' }}>
                                        {
                                            eCommerceList.filter(eCommerce => eCommerce.isShowing && !eCommerce.isBanks).map(eCommerce => {
                                                if (eCommerce.banks) {
                                                    return eCommerce.banks.map((bank, bankIndex) => {
                                                        return (
                                                            <div className='search-partner' onClick={() => showPartner(eCommerce, bankIndex)} key={bank.code}>
                                                                <div>
                                                                    <img src={require(`../components/images/${bank.iconM}`)} alt={`${bank.code}-logo`} className='dropdown-options-partner-image' />
                                                                    <span>
                                                                        {bank.name}
                                                                        <span className='search-partner-category'>{categoriesData[eCommerce.name].categoryName ? ` (${categoriesData[eCommerce.name].categoryName})` : (isTranslated ? ` (${categoriesData[eCommerce.name].translation})` : ` (${eCommerce.name})`)}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                                return (
                                                    <div className='search-partner' onClick={() => showPartner(eCommerce)} key={eCommerce.code}>
                                                        <div>
                                                            <img src={require(`../components/images/${eCommerce.iconM}`)} alt={`${eCommerce.code}-logo`} className='dropdown-options-partner-image' />
                                                            <span>
                                                                {eCommerce.name}
                                                                {
                                                                    eCommerce.category == 'E-Commerce Verification'
                                                                        ? <span className='search-partner-category'>{isTranslated ? ' (Verifikasi)' : ' (Verification)'}</span>
                                                                        : ''
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                        {/* <div className='search-partner' onClick={() => {}} key={'bank.code'}>  
                                            <div>
                                                <img src={require(`../components/images/${'lazada_icon.png'}`)} alt={`${'bank.code'}-logo`} className='dropdown-options-partner-image'/>
                                                <span>
                                                    Lazada
                                                </span>
                                            </div>
                                        </div>
                                        <div className='search-partner' onClick={() => {}} key={'bank.code'}>  
                                            <div>
                                                <img src={require(`../components/images/${'lazada_icon.png'}`)} alt={`${'bank.code'}-logo`} className='dropdown-options-partner-image'/>
                                                <span>
                                                    Shopee
                                                </span>
                                            </div>
                                        </div>
                                        <div className='search-partner' onClick={() => {}} key={'bank.code'}>  
                                            <div>
                                                <img src={require(`../components/images/${'lazada_icon.png'}`)} alt={`${'bank.code'}-logo`} className='dropdown-options-partner-image'/>
                                                <span>
                                                    Ovo
                                                </span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* <div className='categories-container'>
                                {
                                    categories.filter(key => key in category).map(key => {
                                        return (
                                            <div className='category' key={key} id={key}>
                                                <h5>
                                                    {
                                                        isTranslated ? categoriesData[key].translation : key
                                                    }
                                                </h5>
                                                <div className='dropdown-background' style={category[key].isShowing ? {display: 'block', zIndex: '10'} : {display: 'none'}} onClick={() => handleDropdown(key, false)}></div>
                                                <div className='dropdown' style={category[key].isShowing ? {zIndex: '11'} : {zIndex: '11'}}>
                                                    <div className='dropdown-div dropdown-div-p1' onClick={() => handleDropdown(key, !category[key].isShowing)}>  
                                                        {
                                                            false && selectedPartner && eCommerceList[selectedPartner.index].category == key 
                                                            ? (
                                                                <>
                                                                    <div>
                                                                        <img src={require(`../components/images/${eCommerceList[selectedPartner.index].banks ? eCommerceList[selectedPartner.index].banks[[selectedPartner.bankIndex]].icon : eCommerceList[selectedPartner.index].icon}`)} alt={`${eCommerceList[selectedPartner.index].banks ? eCommerceList[selectedPartner.index].banks[[selectedPartner.bankIndex]].code : eCommerceList[selectedPartner.index].code}-logo`} className='dropdown-options-partner-image'/>
                                                                        <span>{eCommerceList[selectedPartner.index].banks ? eCommerceList[selectedPartner.index].banks[[selectedPartner.bankIndex]].name : eCommerceList[selectedPartner.index].name}</span>
                                                                    </div>
                                                                    <i className="arrow down"></i>
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    <span>
                                                                        {
                                                                            isTranslated ? 'Pilih pasangan' :'Select a partner'
                                                                        }
                                                                    </span>
                                                                    <i className="arrow down"></i>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='dropdown-options' style={category[key].isShowing ? {display: 'inline-block'} : {display: 'none'}}>
                                                        {
                                                            category[key].partners.map(index => {
                                                                if(eCommerceList[index].banks){
                                                                    return (
                                                                        eCommerceList[index].banks.map((bank, bankIndex) => {
                                                                            return (
                                                                                <div className='dropdown-options-partner' onClick={() => {if(eCommerceList[index].banks[bankIndex].isSelected) return; handleDropdown(key, false); selectPartner(index, bankIndex); window.gtag('event', 'partner_clicked', { 'partner_name': eCommerceList[index].name, 'bank_name': bank.name }); }} key={bank.code}>  
                                                                                    <div>
                                                                                        <svg style={!bank.isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!bank.isSelected ? "#979595" : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                                                                                        <img src={require(`../components/images/${bank.icon}`)} alt={`${bank.code}-logo`} className='dropdown-options-partner-image'/>
                                                                                        <span>
                                                                                            {bank.name}
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                                            </svg>
                                                                                            <div className='partner-info-icon-message'>
                                                                                                {
                                                                                                    isTranslated ? bank.infoMessageT : bank.infoMessage
                                                                                                }
                                                                                            </div>
                                                                                        </span>
                                                                                        <span className="beta-div" style={eCommerceList[index].isBeta ? {} : {display: 'none'}}>Beta</span>
                                                                                    </div>
                                                                                    {
                                                                                        !bank.isSelected 
                                                                                        ? <i className="arrow arrow-option right"></i> 
                                                                                        : <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} onClick={(event) => {event.stopPropagation(); removePartner(index, bankIndex);}} width="24" height="24" className='remove-icon'/>
                                                                                        // : <svg onClick={(event) => {event.stopPropagation(); removePartner(index, bankIndex);}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                                                    }
                                                                                </div>
                                                                            );
                                                                        })
                                                                    );
                                                                }
                                                                return (
                                                                    <div className='dropdown-options-partner' onClick={() => {if(eCommerceList[index].isSelected) return; handleDropdown(key, false); selectPartner(index); window.gtag('event', 'partner_clicked', { 'partner_name': eCommerceList[index].name });}} key={eCommerceList[index].code}>  
                                                                        <div>
                                                                            <svg style={!eCommerceList[index].isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!eCommerceList[index].isSelected ? '#979595' : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                                                                            <img src={require(`../components/images/${eCommerceList[index].icon}`)} alt={`${eCommerceList[index].code}-logo`} className='dropdown-options-partner-image'/>
                                                                            <span>
                                                                                {eCommerceList[index].name}
                                                                                <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                                </svg>
                                                                                <div className='partner-info-icon-message'>
                                                                                    {
                                                                                        isTranslated ? eCommerceList[index].infoMessageT : eCommerceList[index].infoMessage
                                                                                    }
                                                                                </div>
                                                                            </span>
                                                                            <span className="beta-div" style={eCommerceList[index].isBeta ? {} : {display: 'none'}}>Beta</span>
                                                                        </div>
                                                                        {
                                                                            !eCommerceList[index].isSelected 
                                                                            ? <i className="arrow arrow-option right"></i> 
                                                                            : <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} onClick={(event) => {event.stopPropagation(); removePartner(index);}} width="24" height="24" className='remove-icon'/>
                                                                            // <svg onClick={(event) => {event.stopPropagation(); removePartner(index);}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div> */}
                            {/* <div className='btn-div'>
                                <button type="button" className='btn nav-btn-pwc next-btn' onClick={goNext}>
                                    NEXT
                                </button>
                            </div> */}

                            <div className='categories-container-p2-mobile'>
                                <div className='categories-mobile categories-mobile-p2'>
                                    <div key='connected' id='connected-title' className={'connected' == key ? 'categories-mobile-category categories-mobile-category-selected' : 'categories-mobile-category'} onClick={() => { setKey('connected'); document.getElementById('connected-title').scrollIntoView(); }}>
                                        {
                                            isTranslated ? 'Terhubung' : 'Connected'
                                        }
                                    </div>
                                    {
                                        categories.filter(key => key in category).map(keyI => {
                                            return (
                                                <div key={keyI} id={keyI + '-title'} className={keyI == key ? 'categories-mobile-category categories-mobile-category-selected' : 'categories-mobile-category'} onClick={() => {
                                                    setKey(keyI);
                                                    const element = document.getElementById(keyI + '-title');
                                                    element.scrollIntoView({
                                                        behavior: 'auto',
                                                        block: 'center',
                                                        inline: 'center'
                                                    });
                                                }}>
                                                    {
                                                        categoriesData[keyI].categoryName
                                                            ? categoriesData[keyI].categoryName
                                                            : (isTranslated ? categoriesData[keyI].translation : keyI)
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                <div className='option-div-mobile'>
                                    <div className='dropdown-options-p2-mobile' style={key == 'connected' && eCommerceList.filter(eCommerce => eCommerce.isSelected).length == 0 ? { border: 'none', backgroundColor: '#fff0' } : {}}>
                                        {
                                            key in category && category[key].partners.filter(index => eCommerceList[index].banks || !eCommerceList[index].isSelected).map(index => {
                                                if (eCommerceList[index].banks) {
                                                    return (
                                                        eCommerceList[index].banks.filter(bank => !bank.isSelected).map((bank, bankIndex) => {
                                                            return (
                                                                <div className='dropdown-options-partner' onClick={() => { if (bank.isSelected) return; handleDropdown(key, false); selectPartner(index, eCommerceList[index].banks.indexOf(bank)); window.gtag('event', 'partner_clicked', { 'partner_name': eCommerceList[index].name, 'bank_name': bank.name }); }} key={bank.code}>
                                                                    <div>
                                                                        <svg style={!bank.isSelected && true ? { visibility: 'hidden' } : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!bank.isSelected ? "#979595" : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                                        <img src={require(`../components/images/${bank.iconM}`)} alt={`${bank.code}-logo`} className='dropdown-options-partner-image' />
                                                                        <span>
                                                                            {bank.name}
                                                                            <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => { e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY - 0 + 'px'; e.currentTarget.nextElementSibling.style.left = e.clientX + 10 + 'px'; }} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                            </svg>
                                                                            <div className='partner-info-icon-message'>
                                                                                {
                                                                                    isTranslated ? bank.infoMessageT : bank.infoMessage
                                                                                }
                                                                            </div>
                                                                        </span>
                                                                        <span className="beta-div" style={eCommerceList[index].isBeta ? {} : { display: 'none' }}>Beta</span>
                                                                    </div>
                                                                    {
                                                                        !bank.isSelected
                                                                            ? <i className="arrow arrow-option right"></i>
                                                                            : <svg onClick={(event) => { event.stopPropagation(); removePartner(index, eCommerceList[index].banks.indexOf(bank)); }} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z" /></svg>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    );
                                                }
                                                return (
                                                    <div className='dropdown-options-partner dropdown-options-partner-p2-m' onClick={() => { if (eCommerceList[index].isSelected) return; handleDropdown(key, false); selectPartner(index); window.gtag('event', 'partner_clicked', { 'partner_name': eCommerceList[index].name }); }} key={eCommerceList[index].code}>
                                                        <div>
                                                            <svg style={!eCommerceList[index].isSelected && true ? { visibility: 'hidden' } : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!eCommerceList[index].isSelected ? '#979595' : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                            <img src={require(`../components/images/${eCommerceList[index].iconM}`)} alt={`${eCommerceList[index].code}-logo`} className='dropdown-options-partner-image' />
                                                            <span>
                                                                {eCommerceList[index].name}
                                                                <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => { e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY - 0 + 'px'; e.currentTarget.nextElementSibling.style.left = e.clientX + 10 + 'px'; }} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                </svg>
                                                                <div className='partner-info-icon-message'>
                                                                    {
                                                                        isTranslated ? eCommerceList[index].infoMessageT : eCommerceList[index].infoMessage
                                                                    }
                                                                </div>
                                                            </span>
                                                            <span className="beta-div" style={eCommerceList[index].isBeta ? {} : { display: 'none' }}>Beta</span>
                                                        </div>
                                                        {
                                                            !eCommerceList[index].isSelected
                                                                ? <i className="arrow arrow-option right"></i>
                                                                : <svg onClick={(event) => { event.stopPropagation(); removePartner(index); }} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z" /></svg>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }
                                        {
                                            key == 'connected' && eCommerceList.filter(eCommerce => eCommerce.isSelected && !eCommerce.isBanks).map(eCommerce => {
                                                if (eCommerce.banks) {
                                                    return (
                                                        eCommerce.banks.filter(bank => bank.isSelected).map((bank, bankIndex) => {
                                                            return (
                                                                <div className='dropdown-options-partner' onClick={() => { if (bank.isSelected) return; handleDropdown(key, false); selectPartner('index', eCommerce.banks.indexOf(bank)); }} key={bank.code}>
                                                                    <div>
                                                                        <svg style={!bank.isSelected && true ? { visibility: 'hidden' } : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!bank.isSelected ? "#979595" : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                                        <img src={require(`../components/images/${bank.iconM}`)} alt={`${bank.code}-logo`} className='dropdown-options-partner-image' />
                                                                        <span>
                                                                            {bank.name}
                                                                            {
                                                                                eCommerce.code == 'bankVerification'
                                                                                    ? <span className='search-partner-category'>{isTranslated ? ' (Verifikasi)' : ' (Verification)'}</span>
                                                                                    : ''
                                                                            }
                                                                            <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => { e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY - 0 + 'px'; e.currentTarget.nextElementSibling.style.left = e.clientX + 10 + 'px'; }} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                            </svg>
                                                                            <div className='partner-info-icon-message'>
                                                                                {
                                                                                    isTranslated ? bank.infoMessageT : bank.infoMessage
                                                                                }
                                                                            </div>
                                                                        </span>
                                                                        <span className="beta-div" style={eCommerce.isBeta ? {} : { display: 'none' }}>Beta</span>
                                                                    </div>
                                                                    {
                                                                        !bank.isSelected
                                                                            ? <i className="arrow arrow-option right"></i>
                                                                            : <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} onClick={(event) => { event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce), eCommerce.banks.indexOf(bank)); }} width="24" height="24" className='remove-icon' />
                                                                        // : <svg onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce), bankIndex);}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    );
                                                }
                                                return (
                                                    <div className='dropdown-options-partner dropdown-options-partner-p2-m' onClick={() => { if (eCommerce.isSelected) return; handleDropdown(key, false); selectPartner('index'); }} key={eCommerce.code}>
                                                        <div>
                                                            <svg style={!eCommerce.isSelected && true ? { visibility: 'hidden' } : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!eCommerce.isSelected ? '#979595' : "green"} viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z" /></svg>
                                                            <img src={require(`../components/images/${eCommerce.iconM}`)} alt={`${eCommerce.code}-logo`} className='dropdown-options-partner-image' />
                                                            <span>
                                                                {eCommerce.name}
                                                                {
                                                                    eCommerce.category == 'E-Commerce Verification'
                                                                        ? <span className='search-partner-category'>{isTranslated ? ' (Verifikasi)' : ' (Verification)'}</span>
                                                                        : ''
                                                                }
                                                                <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => { e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY - 0 + 'px'; e.currentTarget.nextElementSibling.style.left = e.clientX + 10 + 'px'; }} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                </svg>
                                                                <div className='partner-info-icon-message'>
                                                                    {
                                                                        isTranslated ? eCommerce.infoMessageT : eCommerce.infoMessage
                                                                    }
                                                                </div>
                                                            </span>
                                                            <span className="beta-div" style={eCommerce.isBeta ? {} : { display: 'none' }}>Beta</span>
                                                        </div>
                                                        {
                                                            !eCommerce.isSelected
                                                                ? <i className="arrow arrow-option right"></i>
                                                                : <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} onClick={(event) => { event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce)); }} width="24" height="24" className='remove-icon' />
                                                            // : <svg onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce));}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }
                                        {
                                            key == 'connected' && eCommerceList.filter(eCommerce => eCommerce.isSelected).length == 0 && (
                                                <div className='option-div-no-selected'>
                                                    {
                                                        isTranslated ? 'Tidak ada mitra yang terhubung' : 'No partners connected'
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='lower-btn-div lower-btn-div-n'>
                            <div className='back-btn-pp btn nav-btn-pwc next-btn' onClick={() => !isUserOnboardingDemo && setIsClientHome(isTranslated ? 'Apakah Anda yakin ingin kembali?' : 'Are you sure you want to go back?')}>
                                {
                                    isTranslated ? 'KEMBALI' : 'BACK'
                                }
                            </div>
                            {/* <button type="button" className='btn nav-btn-pwc back-btn-p' onClick={back}>
                                Back
                            </button> */}
                            <button type="submit" className='btn nav-btn-pwc next-btn' onClick={() => {
                                if (isUserOnboardingDemo) { return; } if (eCommerceList.filter(eCommerce => eCommerce.isSelected).length == 0) {
                                    setShowMessage(isTranslated ? 'Harap pilih setidaknya satu mitra.' : 'Please select atleast one partner.');
                                    return
                                }
                                // setIsSuccess(isTranslated ? 'Data Anda telah berhasil diproses.' : 'Your data has been processed successfully.');
                                setIsSuccessPage(true);
                                window.gtag('event', 'home_submit_clicked');
                            }}>
                                {
                                    isTranslated ? 'AJUKAN' : 'SUBMIT'
                                }
                            </button>
                            {/* <button type="button" className='btn nav-btn-pwc back-btn-p-m' onClick={back}>
                                Back
                            </button> */}
                        </div>
                        <BrandBadge option={"footer"} />
                    </>
                )
            }
            {
                !isMobileView && false && (
                    <div className='partners-list-n'>
                        {/* <div className='partner-n-top-heading'>
                            {
                                isTranslated ? 'Choose your account' : 'Choose your account'
                            }
                        </div> */}
                        <div className='partners-list-heading-n'>
                            <span>
                                {/* {
                                    isTranslated ? 'Data Partners' : 'Data Partners'
                                } */}
                                {
                                    isTranslated ? 'Pilih Mitra' : 'Select a Partner'
                                }
                                {/* <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon' viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                                <div className='partner-info-icon-message'>
                                    {
                                        isTranslated ? 'xyz ' : 'xyz'
                                    }
                                </div> */}
                            </span>
                        </div>
                        <div className='category-toogle-n'>
                            <div className={`category-toogle-value-n ${!isToggleVerification ? 'category-toogle-value-selected-n' : ''}`} onClick={() => tooglePartners(false)}>
                                {
                                    isTranslated ? 'Akun Penjual/Pedagang' : 'Seller/Merchant account '
                                }
                            </div>
                            <div className={`category-toogle-value-n ${isToggleVerification ? 'category-toogle-value-selected-n' : ''}`} onClick={() => tooglePartners(true)}>
                                {
                                    isTranslated ? 'KYC Penjual/Pedagang' : 'Seller/Merchant KYC'
                                }
                            </div>
                        </div>
                        <div className='partners-list-partners-n'>
                            {
                                eCommerceList.filter(eCommerce => eCommerce.isShowing && !eCommerce.isHidden).map(eCommerce => {
                                    if (eCommerce.banks && false) {
                                        return eCommerce.banks.map((bank, bankIndex) => {
                                            return (
                                                <div className={`partner-container-n ${bank.isSelected ? 'partner-container-n-selected' : ''}`} onClick={() => partnerClicked(eCommerceList.indexOf(eCommerce), bankIndex)} key={bank.code}>
                                                    <div className='partner-div-n'>
                                                        {/* <svg style={!bank.isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!bank.isSelected ? "#979595" : "green"} className='svg-selected-n' viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg> */}
                                                        <img src={require(`../components/images/${bank.icon}`)} alt={`${bank.code}-logo`} className='partner-image-n' />
                                                        <span>
                                                            {/* {bank.name} */}
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon partner-info-icon-n' viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                            </svg>
                                                            <div className='partner-info-icon-message'>
                                                                {
                                                                    isTranslated ? bank.infoMessageT : bank.infoMessage
                                                                }
                                                            </div> */}
                                                        </span>
                                                        {/* <span className="beta-div beta-div-n" style={eCommerce.isBeta ? {} : {display: 'none'}}>Beta</span> */}
                                                    </div>
                                                    {
                                                        // bank.isSelected &&  <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce), bankIndex);}} width="24" height="24" className='remove-icon'/>
                                                        // : <svg onClick={(event) => {event.stopPropagation(); removePartner(index, bankIndex);}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                    }
                                                    {/* <div className='partner-category-n'>
                                                        {
                                                            isTranslated ? categoriesData[eCommerce.category].translation : eCommerce.category
                                                        }    
                                                    </div> */}
                                                </div>
                                            );
                                        })
                                    }
                                    return (
                                        <div className={`partner-container-n ${eCommerce.isSelected ? 'partner-container-n-selected' : ''} ${selectedPartner && selectedPartner.index === eCommerceList.indexOf(eCommerce) ? 'partner-container-n-showing' : ''}`} onClick={() => partnerClicked(eCommerceList.indexOf(eCommerce))} key={eCommerce.code}>
                                            <div className='partner-div-n'>
                                                {/* <svg style={!eCommerce.isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!eCommerce.isSelected ? '#979595' : "green"} className='svg-selected-n' viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg> */}
                                                <img style={!eCommerce.isSelected ? { display: 'none' } : {}} src={require(`../components/images/images/check_1.png`)} alt='check_icon' className='selected-icon-n' />

                                                <img src={require(`../components/images/${eCommerce.icon}`)} alt={`${eCommerce.code}-logo`} className='partner-image-n' style={eCommerce.iconWidth ? { width: eCommerce.iconWidth } : {}} />
                                                <span>
                                                    {/* {eCommerce.name} */}
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon partner-info-icon-n' viewBox="0 0 16 16">
                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                    </svg>
                                                    <div className='partner-info-icon-message'>
                                                        {
                                                            isTranslated ? eCommerce.infoMessageT : eCommerce.infoMessage
                                                        }
                                                    </div> */}
                                                </span>
                                                {/* <span className="beta-div beta-div-n" style={eCommerce.isBeta ? {} : {display: 'none'}}>Beta</span> */}
                                            </div>
                                            {
                                                // eCommerce.isSelected && <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce));}} width="24" height="24" className='remove-icon'/>
                                                eCommerce.isSelected && <img src={require(`../components/images/images/delete-2.png`)} alt={`remove-icon`} onClick={(event) => { event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce)); }} width="24" height="24" className='remove-icon remove-icon-n' />
                                                // <svg onClick={(event) => {event.stopPropagation(); removePartner(index);}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                            }
                                            <div className='partner-category-n'>
                                                {/* {
                                                    isTranslated ? categoriesData[eCommerce.category].translation : eCommerce.category
                                                }     */}
                                                {/* {
                                                    eCommerce.name
                                                } */}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>

                    </div>
                )
            }
            {
                !isMobileView && (
                    <div className='partners-list-n'>
                        <div className='partners-list-heading-n'>
                            <span>
                                {
                                    isTranslated ? 'Pilih Mitra' : 'Select a Partner'
                                }
                            </span>
                        </div>
                        <div className='partner-list-cont'>
                            {
                                categories.filter(key => key in category).map(key => {
                                    return (
                                        <div className='categeory-conatiner' key={key} id={key}>
                                            <h5>
                                                {
                                                    categoriesData[key].categoryName 
                                                        ? categoriesData[key].categoryName
                                                        : (isTranslated && key == "Bank E-statement Upload" ? "Bank Rekening Koran Upload" 
                                                        : !isTranslated && key == "Bank E-statement Upload" ? "Bank Statements Upload"
                                                        : (isTranslated ? categoriesData[key].translation : key))
                                                }
                                            </h5>
                                            <div className='partners-list-partners-n'>
                                                {
                                                    category[key].partners.map(index => {
                                                        let eCommerce = eCommerceList[index];
                                                        if (eCommerce.isShowing && !eCommerce.isHidden) {
                                                            if (eCommerce.banks && eCommerce.code != 'bankVerification') {
                                                                return eCommerce.banks.map((bank, bankIndex) => {
                                                                    return (
                                                                        <div className={`partner-container-n ${bank.isSelected ? 'partner-container-n-selected' : ''} ${selectedPartner && selectedPartner.index === eCommerceList.indexOf(eCommerce) && selectedPartner.bankIndex == bankIndex ? 'partner-container-n-showing' : ''}`} onClick={() => partnerClicked(eCommerceList.indexOf(eCommerce), bankIndex)} key={bank.code}>
                                                                            <div className='partner-div-n'>
                                                                                {/* <svg style={!bank.isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!bank.isSelected ? "#979595" : "green"} className='svg-selected-n' viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg> */}
                                                                                <img style={!bank.isSelected ? { display: 'none' } : {}} src={require(`../components/images/images/check_1.png`)} alt='check_icon' className='selected-icon-n' />

                                                                                <img src={require(`../components/images/${bank.icon}`)} alt={`${bank.code}-logo`} className='partner-image-n' style={bank.iconWidth ? { width: bank.iconWidth } : {}} />
                                                                                <span>
                                                                                    {/* {bank.name} */}
                                                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon partner-info-icon-n' viewBox="0 0 16 16">
                                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                                    </svg>
                                                                                    <div className='partner-info-icon-message'>
                                                                                        {
                                                                                            isTranslated ? bank.infoMessageT : bank.infoMessage
                                                                                        }
                                                                                    </div> */}
                                                                                </span>
                                                                                {/* <span className="beta-div beta-div-n" style={eCommerce.isBeta ? {} : {display: 'none'}}>Beta</span> */}
                                                                            </div>
                                                                            {
                                                                                bank.isSelected && <img src={require(`../components/images/images/cancel.png`)} alt={`remove-icon`} onClick={(event) => { event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce), bankIndex); }} width="24" height="24" className='remove-icon remove-icon-n' />
                                                                                // : <svg onClick={(event) => {event.stopPropagation(); removePartner(index, bankIndex);}} width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                                                // bank.isSelected && <svg alt={`remove-icon`} className='remove-icon remove-icon-n' width="24" height="24" onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce), bankIndex);}} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 252 252" fill="#424242" space="preserve"><g><path d="M126,0C56.523,0,0,56.523,0,126s56.523,126,126,126s126-56.523,126-126S195.477,0,126,0z M126,234 c-59.551,0-108-48.449-108-108S66.449,18,126,18s108,48.449,108,108S185.551,234,126,234z"/><path d="M164.612,87.388c-3.515-3.515-9.213-3.515-12.728,0L126,113.272l-25.885-25.885c-3.515-3.515-9.213-3.515-12.728,0 c-3.515,3.515-3.515,9.213,0,12.728L113.272,126l-25.885,25.885c-3.515,3.515-3.515,9.213,0,12.728 c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636L126,138.728l25.885,25.885c1.757,1.757,4.061,2.636,6.364,2.636 s4.606-0.879,6.364-2.636c3.515-3.515,3.515-9.213,0-12.728L138.728,126l25.885-25.885 C168.127,96.601,168.127,90.902,164.612,87.388z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>

                                                                            }
                                                                            {/* <div className='partner-category-n'>
                                                                                {
                                                                                    isTranslated ? categoriesData[eCommerce.category].translation : eCommerce.category
                                                                                }    
                                                                            </div> */}
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                            return (
                                                                <div className={`partner-container-n ${eCommerce.isSelected ? 'partner-container-n-selected' : ''} ${selectedPartner && selectedPartner.index === eCommerceList.indexOf(eCommerce) ? 'partner-container-n-showing' : ''}`} onClick={() => partnerClicked(eCommerceList.indexOf(eCommerce))} key={eCommerce.code}>
                                                                    <div className='partner-div-n'>
                                                                        {/* <svg style={!eCommerce.isSelected && true ? {visibility: 'hidden'} : {}} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={!eCommerce.isSelected ? '#979595' : "green"} className='svg-selected-n' viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg> */}
                                                                        <img style={!eCommerce.isSelected ? { display: 'none' } : {}} src={require(`../components/images/images/check_1.png`)} alt='check_icon' className='selected-icon-n' />

                                                                        <img src={require(`../components/images/${eCommerce.icon}`)} alt={`${eCommerce.code}-logo`} className='partner-image-n' style={eCommerce.iconWidth ? { width: eCommerce.iconWidth } : {}} />
                                                                        <span>
                                                                            {/* {eCommerce.name} */}
                                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" onMouseOver={(e) => {e.currentTarget.nextElementSibling.style.display = 'inline-block'; e.currentTarget.nextElementSibling.style.top = e.clientY-0+'px'; e.currentTarget.nextElementSibling.style.left = e.clientX+10+'px';}} onMouseOut={(e) => e.currentTarget.nextElementSibling.style.display = 'none'} width="13" height="13" fill="black" className='partner-info-icon partner-info-icon-n' viewBox="0 0 16 16">
                                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                                                            </svg>
                                                                            <div className='partner-info-icon-message'>
                                                                                {
                                                                                    isTranslated ? eCommerce.infoMessageT : eCommerce.infoMessage
                                                                                }
                                                                            </div> */}
                                                                        </span>
                                                                        {/* <span className="beta-div beta-div-n" style={eCommerce.isBeta ? {} : {display: 'none'}}>Beta</span> */}
                                                                    </div>
                                                                    {
                                                                        eCommerce.isSelected && <img src={require(`../components/images/images/cancel.png`)} alt={`remove-icon`} onClick={(event) => { event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce)); }} width="24" height="24" className='remove-icon  remove-icon-n' />
                                                                        // eCommerce.isSelected && <img src={require(`../components/images/images/close-icon.svg`)} alt={`remove-icon`} onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce));}} width="24" height="24" className='remove-icon remove-icon-n'/>
                                                                        // <svg onClick={(event) => {event.stopPropagation(); removePartner(index);}} className='remove-icon remove-icon-n' width="24" height="24" fill="#878787" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z"/></svg>
                                                                        // <svg onClick={(event) => {event.stopPropagation(); removePartner(index);}} className='remove-icon remove-icon-n' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="122.881px" height="122.88px" viewBox="0 0 122.881 122.88" enable-background="new 0 0 122.881 122.88" xml:space="preserve"><g><path d="M61.44,0c16.966,0,32.326,6.877,43.445,17.996c11.119,11.118,17.996,26.479,17.996,43.444 c0,16.967-6.877,32.326-17.996,43.444C93.766,116.003,78.406,122.88,61.44,122.88c-16.966,0-32.326-6.877-43.444-17.996 C6.877,93.766,0,78.406,0,61.439c0-16.965,6.877-32.326,17.996-43.444C29.114,6.877,44.474,0,61.44,0L61.44,0z M80.16,37.369 c1.301-1.302,3.412-1.302,4.713,0c1.301,1.301,1.301,3.411,0,4.713L65.512,61.444l19.361,19.362c1.301,1.301,1.301,3.411,0,4.713 c-1.301,1.301-3.412,1.301-4.713,0L60.798,66.157L41.436,85.52c-1.301,1.301-3.412,1.301-4.713,0c-1.301-1.302-1.301-3.412,0-4.713 l19.363-19.362L36.723,42.082c-1.301-1.302-1.301-3.412,0-4.713c1.301-1.302,3.412-1.302,4.713,0l19.363,19.362L80.16,37.369 L80.16,37.369z M100.172,22.708C90.26,12.796,76.566,6.666,61.44,6.666c-15.126,0-28.819,6.13-38.731,16.042 C12.797,32.62,6.666,46.314,6.666,61.439c0,15.126,6.131,28.82,16.042,38.732c9.912,9.911,23.605,16.042,38.731,16.042 c15.126,0,28.82-6.131,38.732-16.042c9.912-9.912,16.043-23.606,16.043-38.732C116.215,46.314,110.084,32.62,100.172,22.708 L100.172,22.708z"/></g></svg>
                                                                        // eCommerce.isSelected && <svg alt={`remove-icon`} className='remove-icon remove-icon-n' width="24" height="24" onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce));}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.881 122.88" enable-background="new 0 0 122.881 122.88" space="preserve"><g><path d="M61.44,0c16.966,0,32.326,6.877,43.445,17.996c11.119,11.118,17.996,26.479,17.996,43.444 c0,16.967-6.877,32.326-17.996,43.444C93.766,116.003,78.406,122.88,61.44,122.88c-16.966,0-32.326-6.877-43.444-17.996 C6.877,93.766,0,78.406,0,61.439c0-16.965,6.877-32.326,17.996-43.444C29.114,6.877,44.474,0,61.44,0L61.44,0z M80.16,37.369 c1.301-1.302,3.412-1.302,4.713,0c1.301,1.301,1.301,3.411,0,4.713L65.512,61.444l19.361,19.362c1.301,1.301,1.301,3.411,0,4.713 c-1.301,1.301-3.412,1.301-4.713,0L60.798,66.157L41.436,85.52c-1.301,1.301-3.412,1.301-4.713,0c-1.301-1.302-1.301-3.412,0-4.713 l19.363-19.362L36.723,42.082c-1.301-1.302-1.301-3.412,0-4.713c1.301-1.302,3.412-1.302,4.713,0l19.363,19.362L80.16,37.369 L80.16,37.369z M100.172,22.708C90.26,12.796,76.566,6.666,61.44,6.666c-15.126,0-28.819,6.13-38.731,16.042 C12.797,32.62,6.666,46.314,6.666,61.439c0,15.126,6.131,28.82,16.042,38.732c9.912,9.911,23.605,16.042,38.731,16.042 c15.126,0,28.82-6.131,38.732-16.042c9.912-9.912,16.043-23.606,16.043-38.732C116.215,46.314,110.084,32.62,100.172,22.708 L100.172,22.708z"/></g></svg>
                                                                        // eCommerce.isSelected && <svg alt={`remove-icon`} className='remove-icon remove-icon-n' width="24" height="24" onClick={(event) => {event.stopPropagation(); removePartner(eCommerceList.indexOf(eCommerce));}} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 252 252" fill="#424242" space="preserve"><g><path d="M126,0C56.523,0,0,56.523,0,126s56.523,126,126,126s126-56.523,126-126S195.477,0,126,0z M126,234 c-59.551,0-108-48.449-108-108S66.449,18,126,18s108,48.449,108,108S185.551,234,126,234z"/><path d="M164.612,87.388c-3.515-3.515-9.213-3.515-12.728,0L126,113.272l-25.885-25.885c-3.515-3.515-9.213-3.515-12.728,0 c-3.515,3.515-3.515,9.213,0,12.728L113.272,126l-25.885,25.885c-3.515,3.515-3.515,9.213,0,12.728 c1.757,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636L126,138.728l25.885,25.885c1.757,1.757,4.061,2.636,6.364,2.636 s4.606-0.879,6.364-2.636c3.515-3.515,3.515-9.213,0-12.728L138.728,126l25.885-25.885 C168.127,96.601,168.127,90.902,164.612,87.388z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>

                                                                    }
                                                                    <div className='partner-category-n'>
                                                                        {/* {
                                                                            isTranslated ? categoriesData[eCommerce.category].translation : eCommerce.category
                                                                        }     */}
                                                                        {/* {
                                                                            eCommerce.name
                                                                        } */}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className='lower-btn-div lower-btn-div-d'>
                            <div className='back-btn-pp back-btn-pp-n2 btn next-btn btn-sumbit-split-ui nav-btn-pwc' onClick={() => !isUserOnboardingDemo && setIsClientHome(isTranslated ? 'Apakah kamu yakin ingin kembali?' : 'Are you sure you want to go back?')}>
                                {
                                    isTranslated ? 'Kembali' : 'Back'
                                }
                            </div>
                            {/* <button type="button" className='btn nav-btn-pwc back-btn-p' onClick={back}>
                                Back
                            </button> */}
                            <button type="submit" className='btn nav-btn-pwc next-btn btn-sumbit-split-ui' onClick={() => {
                                if (isUserOnboardingDemo) { return; } if (eCommerceList.filter(eCommerce => eCommerce.isSelected).length == 0) {
                                    setShowMessage(isTranslated ? 'Kamu harus memilih platform penjualan' : 'Please select atleast one partner.');
                                    return
                                }
                                // setIsSuccess(isTranslated ? 'Data Anda telah berhasil diproses.' : 'Your data has been processed successfully.');
                                setIsSuccessPage(true);
                                window.gtag('event', 'home_submit_clicked');
                            }}>
                                {
                                    isTranslated ? 'AJUKAN' : 'Submit'
                                }
                            </button>
                            {/* <button type="button" className='btn nav-btn-pwc back-btn-p-m' onClick={back}>
                                Back
                            </button> */}
                        </div>
                        <BrandBadge option={"footer"} />
                    </div>
                )
            }
            {((!isMobileView && selectedPartner) || (isMobileView && showPartnerPage)) && <PartnerPageNew isMobileView={isMobileView} eCommerceList={eCommerceList} index={selectedPartner.index} bankIndex={selectedPartner.bankIndex} categoriesData={categoriesData} isTranslated={isTranslated} updateParams={updateParams} updateParamsOTP={updateParamsOTP} back={() => { setShowPartnerPage(false); const newECommerceList = [...eCommerceList]; newECommerceList[selectedPartner.index] = { ...newECommerceList[selectedPartner.index] }; newECommerceList[selectedPartner.index].isInitiated = false; newECommerceList[selectedPartner.index].authCounterVar = 0; setECommerceList(newECommerceList); }} processSuccess={processSuccess} showError={showError} verificationStep={verificationStep} isJulo={isJulo} />}

            {showConfirmModal && <Confirmation isTranslated={isTranslated} partner={confirmData.bankIndex ? eCommerceList[confirmData.index].banks[confirmData.bankIndex].name : eCommerceList[confirmData.index].name} confirmed={() => unSelectPartner(confirmData.index, confirmData.bankIndex)} closeMessage={() => setShowConfirmModal(false)} />}

            {isClientHome && <Confirmation isTranslated={isTranslated} message={isClientHome} confirmed={() => { backToHome(); window.gtag('event', 'home_back_clicked'); }} closeMessage={() => setIsClientHome(false)} />}

            {showMessage && <Message isTranslated={isTranslated} message={showMessage} title={title} closeMessage={closeInfoMessage} isShowOnLoaderTop={isShowOnLoaderTop} />}

            {isLoading && <FullPageLoader />}
            {isShowModal && <ShowModel submitBtn={submitBtn} closeModal={() => { setIsShowModal(false);  setIssubmitted(false);}} issubmitted={issubmitted} isTranslated={isTranslated} fileData={fileData} />}
            {isSuccessPage && <SuccessModalSubmit isTranslated={isTranslated} closeResponse={backToHome} message={isSuccessPage} />}
            {isError && <ErrorModal isTranslated={isTranslated} closeResponse={closeResponse} errorMessage={errorMessage} />}
            {showOTPModal && <OTPModal isTranslated={isTranslated} index={showOTPModal.index} eCommerce={showOTPModal.eCommerce} updateParamsOTP={updateParamsOTP} close={() => setShowOTPModal(false)} />}
            {isSuccess && !isShowModal && (issubmitted || changeData) && <SuccessModal isTranslated={isTranslated} closeResponse={isSuccess !== true ? backToHome : () => setIsSuccess(false)} message={isSuccess !== true ? isSuccess : false} />}
        </div>
    );
}

export default PartnerNew;